import { Box, Button, IconButton, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import ArrowBackIcon  from  '@mui/icons-material/ArrowBack';
import { useContext, useEffect } from "react";
import LoginContent from "src/components/accountless/LoginContent";
import AuthContext from "src/contexts/JWTContext";
import theme from "src/theme/globalTheme";
import { trackEvent } from "src/utils/analytics";
import Offline from "src/components/Offline";

export const offline = true;

 const SignInPage = () => {
   const theme = useTheme();
   const auth = useContext(AuthContext);
   const isMobileDevice = useMediaQuery(theme.breakpoints.down('md'));

   useEffect(()=>{

      if(auth.getAnalytics()) {
         try {
           if(window.localStorage.getItem('actionsData')) {
               auth.getAnalytics().track('LOGIN_PAGEVIEW');
           } else {
               auth.getAnalytics().track('LOGIN_PAGEVIEW_NEW');
           }
         } catch(e) {
           console.log(e.message);
         }
       } else {
         if(window.localStorage.getItem('actionsData')) {
            trackEvent('LOGIN_PAGEVIEW')
         } else {
            trackEvent('LOGIN_PAGEVIEW_NEW')
         }
       } 

   }, [])

   async function getStartedClicked() {
      if(auth.getAnalytics()) {
         try {
           if(window.localStorage.getItem('actionsData')) {
             await auth.getAnalytics().track('GETTING_STARTED_CLICK_RETURNING_USER');
           } else {
             await auth.getAnalytics().track('GETTING_STARTED_CLICK');
           }
         } catch(e) {
           console.log(e.message);
         }
       } else {
         if(window.localStorage.getItem('actionsData')) {
           await trackEvent('GETTING_STARTED_CLICK_RETURNING_USER')
         } else {
           await trackEvent('GETTING_STARTED_CLICK')
         }
       } 

       window.location.href=window.location.href.includes('localhost')?'http://localhost:3000/welcome/':'https://www.durodogs.com/welcome/'
   }
    return (<>
    <Box sx={{background: 'transparent linear-gradient(180deg, #0F7AE8 0%, #5662B8 100%) 0% 0% no-repeat padding-box;', backgroundImage: offline?'url(/static/off.png)':undefined, backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat', width: '100%'}}>
    <IconButton onClick={()=>{window.history.back()}} sx={{position: 'absolute', left: 0, top: '0.7em', zIndex: 1, color: 'white'}}><ArrowBackIcon /></IconButton>
       <Stack justifyContent={{md: 'center', xs: 'flex-start'}} alignItems='center' sx={{width: '100%', minHeight: '100vh'}}>
      <Box sx={{width: {xs: '72vw', md: 460}, minWidth: 260, maxWidth: '90%'}}>
         <Typography sx={{color: 'white', fontSize: {xs: '6vw', md: 36}, width: '100%', textAlign: 'center', my: '1em', fontWeight: 'bold'}}>Welcome to Duro Dogs!</Typography>
         <Stack direction='row' justifyContent='center' alignItems='center' sx={{width: '100%'}}><img style={{width: '80%'}} src='/static/home/logos/logo2_light.png' alt='logo' /></Stack>
         
         {offline? <Offline />:<LoginContent />}

       </Box>
       
       <Stack justifyContent='flex-end' alignItems='center' sx={{width: '100%', marginBottom: '1em', flexGrow: 1}}>
        <Typography sx={{color: 'white', width: '100%', textAlign: 'center'}}>Don't have a Duro Dogs Account?</Typography>
        <Button sx={{color: 'white', textDecoration: 'underline', textTransform: 'none'}} onClick={getStartedClicked}>Get Started for Free</Button>
        </Stack>
       </Stack>
      
      
    </Box>
    
    </>);
 }

 export default SignInPage;
