import { useState } from 'react';
import type { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@mui/material/styles';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../theme/globalTheme'
import ErrorBoundary from '../ErrorBoundary';
import useVhUnitWorkaround, { vh } from 'src/hooks/useVhUnitWorkaround';

interface DashboardLayoutProps {
  children?: ReactNode;
}

const DashboardLayoutRoot = experimentalStyled('div')(
  ({ theme }) => (
    {
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      height: '100%',
      overflow: 'hidden',
      width: '100%'
    }
  )
);

const DashboardLayoutWrapper = experimentalStyled('div')(
  ({ theme }) => (
    {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden',
      marginTop: vh(6.5),
      [theme.breakpoints.down('lg')]: {
        'body[playground] &': {
          marginTop: 0,
        },
      },
      
      [theme.breakpoints.up('lg')]: {
        paddingLeft: '280px'
      }
    }
  )
);

const DashboardLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const DashboardLayoutContent = experimentalStyled('div')(
  ({ theme }) => (
    {
      flex: '1 1 auto',
      height: '100%',
      overflow: 'auto',
      [theme.breakpoints.up('xs')]: {
        overflow: 'hidden'
      },
      position: 'relative',
      WebkitOverflowScrolling: 'touch'
    }
  )
)

const DashboardLayout: FC<DashboardLayoutProps> = () => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState<boolean>(false);
  useVhUnitWorkaround();

  return (
    
    <ThemeProvider theme={theme}>
      <DashboardLayoutRoot>
        <DashboardNavbar
          onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)}
        />
        <DashboardSidebar
          onMobileClose={(): void => setIsSidebarMobileOpen(false)}
          openMobile={isSidebarMobileOpen}
        />
        <DashboardLayoutWrapper>
          <DashboardLayoutContainer>
            <DashboardLayoutContent>
                <Outlet />
            </DashboardLayoutContent>
          </DashboardLayoutContainer>
        </DashboardLayoutWrapper>
      </DashboardLayoutRoot>
    </ThemeProvider>
    
  );
};

export default DashboardLayout;
