import { Stack, Typography } from "@mui/material"

const Offline = () => {
    return (
        <>
            <Stack sx={{}} justifyContent='center' alignItems='center'>
                <Typography sx={{color: 'black', fontSize: '2.3em', margin: '1em', textAlign: 'center'}}> Currently offline, please check back later.</Typography>
            </Stack>
            

        </>
    )
}

export default Offline;