import type { FC } from 'react';
import {
  Box,
  Grid,
  Icon,
  IconButton,

  Typography
} from '@mui/material';


const Footer: FC = (props) => {
  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: 'primary.dark',
        py: {
          xs: '2vw',
          md: '2vw',
        },
        px:{
          xs:0,
          md:0,
        }
      }}
      // {...props}
    >

    <Grid  container sx={{ color: 'white', px: {xs: '11vw', md: '11vw'}}}>
        <Grid item container xs={12} md={8} gap={{xs: 2, md: 8}}  justifyContent={{xs: 'space-between', md: 'flex-start'}}>
          <Grid item container xs={5} md={3} justifyContent='center' alignItems='center'>
            <Grid item xs={12}><img style={{width: '100%'}} src='/static/home/logos/logo2_light.png' alt='' /></Grid>
            <Grid item xs={12}><Typography sx={{width: '100%', fontSize: {xs: '2.5vw', md: '1vw'}, textAlign: 'left'}}>© 2022 Duro Dogs</Typography></Grid>
          </Grid>
          <Grid item container xs={5} md={3} onClick={()=>{window.open('https://www.nftyjigs.com')}} justifyContent='center' alignItems='center'>
            <Grid item xs={12}><Typography sx={{width: '100%', fontSize: {xs: '2.5vw', md: '1.5vw'}, textAlign: 'center'}}>POWERED BY</Typography></Grid>
            <Grid item xs={12}><img style={{width: '100%'}} src='/static/home/ASSETLAYER.png' alt='' /></Grid>
            <Grid item xs={12}><Typography sx={{width: '100%', fontSize: {xs: '2.5vw', md: '1.5vw'}, textAlign: 'center'}}>AssetLayer.com</Typography></Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12} md={4} sx={{paddingTop: {xs: '5vw', md: 0}}}>
          <Grid item xs={12}>
            <Typography sx={{fontSize: {xs: '5.5vw', md: '1.7vw'}, textAlign: {xs: 'left', md: 'right'}}}>FOLLOW US</Typography>
          </Grid>
          <Grid item container xs={12} sx={{marginLeft: {xs: '-3vw', md: 0}}} justifyContent={{xs: 'flex-start', md: "flex-end"}}>        
            <Grid item xs={2} sx={{}}><IconButton sx={{width: '100%'}} onClick={()=>{window.location.href='https://discord.gg/pbqqQUmvQ5'}}><img style={{width: '100%'}} src='/static/icons/discord.svg' alt='' /></IconButton></Grid>
            <Grid item xs={2} sx={{}}><IconButton sx={{width: '100%'}} onClick={()=>{window.location.href='https://twitter.com/DuroDogs'}}><img style={{width: '100%'}} src='/static/icons/twitter.svg' alt='' /></IconButton></Grid>
          </Grid>
        </Grid>
    </Grid>
    </Box>
  )
};

export default Footer;
