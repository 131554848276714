import axios, {AxiosError} from 'axios';

export const HOST:string =  'https://api.durodogs.com';
export const API_VERSION = '/api/v1';
export const googleClientId =  '75907253964-78f4p3fca4d605f3usfjj1k5f7gglhrn.apps.googleusercontent.com';
export const facebookAppId = '3208600039360279';
//test accessToken, later to be replaced by the token from local storage in commented line below
//export const accessToken:string = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJoYW5kbGUiOiJ0YXN0ZSIsImF2YXRhclVybCI6Imh0dHBzOi8vYmV0YS1jbG91ZC5oYW5kY2FzaC5pby92Mi91c2Vycy9wcm9maWxlUGljdHVyZS90YXN0ZSIsInBlcm1pc3Npb25zIjpbeyJfaWQiOiI2MTU3NTVjNTNkNDE2OWVmMjRhZjMxOTEiLCJoYW5kbGUiOiJ0YXN0ZSIsInB1YmtleSI6IjAzYjA3OTgyNWVkZWUwY2M2NTVkZDkxM2RjODA0MGFkMjNhYTFiNjExNDJjMTFjZGY4MThmYzhlMjEwMGFhYjliNyIsImFjdGl2ZSI6dHJ1ZSwiYWRtaW4iOmZhbHNlLCJhcHBJZCI6ImE3MzMzYTJiN2JlNGYwYjI4ZmM5MDI5ZTZkMGZjZjE2IiwiYXBwTmFtZSI6Im5mdHlqaWdzIiwiZnJpZW5kcyI6dHJ1ZSwiaXNzdWUiOnRydWUsInJvbGUiOiJ1c2VyIiwic2lnbiI6dHJ1ZSwic3BlbmQiOnRydWUsInRpbWUiOjE2MzMxMTM1NDA5OTcsImVuYyI6IjQyNDk0NTMxMDM2YWY4MDQ0ZWI1NzYzNTMzYTA4YTM1ZThkNmFkMTZiOGRjZGNmNTQ0OTlmYTJiZTJlZTcwOWJiZjhjZjlmM2Q5MmEyNWMzYWRjM2UyOTU0MTRiMTUzN2M0MTQ0NGRhNmJkNTY1YWM0NGNhNDA5NTU1OGUwMmMyNmY3ZDYyNWY2ODBlYTAzYWRjOGRhODdhMDg5ZjhmYTk5YjI4YjhlYmFmNjhkOWM4Mjc3ZTIwOGFmYWU0ODE3Y2ZkYTZlODQ4MDJiNDhlMjkyZjA4ZGIxNjU0NzIzMTU5YjQyYmZiMTI2MzE5NDU4YTRjNDQxNzdhNTkyMDUwMDgyOTc1ZjRkYjI0MzBhMTRkNmZkZTdkYjhjZmI5NjVhZDA2ZTI5YWEwZGMifV0sImlhdCI6MTYzMzk5NjA3NiwiZXhwIjoxNjM0MjU1Mjc2fQ.LOUpcIJWipOGw91kqEE9zGLCepXOUUvZOICIMpQgw0OGZIx07n0OzG6z2qhB64h-oesHNoh6_DQed7KgvQ1lL3BoGwr9x77FfOUgkyRF72iKgIU5mFMZ6FMZMm_4XTwoOE9HojJ4cy41spnB1rHZy1MO_xlbRzhiS9VlHKZ-_oktwwYsFmJVp6ELW5UpIl44KSt11I-nD4y-fy9kiL1Dldh2LsfaGIUeB63ugg4ra1DqgspTZfQn121UtDc5eZnZKfYLZSw1qq0iMnYIWZ_uX4DZFyGrATZscodov7MyjB5lCYHHkel5WNOKpNYAVmUxPL2OU6kFZJ4LMMIEEQLG9_xMs_fhOF3OsUcWo2y8aiqbkX2UVAZNulrCTOSJ8599skY8Y5wbr3xWju0Oak-PPZuOM4SfJIYuEUguWazW4MBgJ2DO1vdSFujSfswOL7x590TUqVMD0pDOzp49RG0YUSfBpsMMs4LWsDpcvUxvioRjU1HMYGFJvwPjIqaVzMZK7hMe3o9AZYTQWBjVgz9G-OmWpVe8wsTK-kh7NNfQ5q9tUD7QrjxlbkQf4qBwp3A8vUw7T2GBmEjy3YJiXG5RxaNzDB5MJXmETQa_sS8qDK099zm1yNLWLWwAvxFk_3RO7zcK69L9cBJQ5P7qAEWskwcT9lYjjl4Fi7bTh-DAWw0';
export const accessToken:string = window.localStorage.getItem('accessToken');
export const axiosAuthConfig = { 
    headers: {
      authorization: `Bearer ${accessToken}`
    }
  };

export function checkAuth() {
  let accessToken:string = window.localStorage.getItem('accessToken');
  if (!accessToken) {
    accessToken = window.localStorage.getItem('accessToken2');
  }
  const axiosAuthConfig = { 
    headers: {
      authorization: `Bearer ${accessToken}`
    }
  };
  if(!accessToken) {
    throw new Error('please log back in, no access token');
  }
  return axiosAuthConfig;

}

export const handleError = (error:Error, reject)=>
{
  if(axios.isAxiosError(error))
  {
    const axiosError:AxiosError = error;
    console.error(axiosError.response?.data);
    if(axiosError.response) {
      if (((axiosError.response?.data) as string).includes('expired')) {
        setTimeout(()=>{window.location.reload()}, 2500);
      }
      reject(new Error((axiosError.response?.data) as string));
    } else {
      if (error.message.includes('expired')) {
        setTimeout(()=>{window.location.reload()}, 2500);
      }
      reject(error);
    }
    
  }
  else
  {
    console.error(error);
    if (error.message.includes('expired')) {
      setTimeout(()=>{window.location.reload()}, 2500);
    }
    reject(error);
  }
  
}

export const handleError2 = (error:Error, reject)=>
{
  if(axios.isAxiosError(error))
  {
    const axiosError:AxiosError = error;
    console.error(axiosError.response?.data);
    if(axiosError.response) {
      if (((axiosError.response?.data) as string).includes('expired')) {
        setTimeout(()=>{window.location.reload()}, 2500);
      }
      console.log(axiosError.response);
      reject(axiosError.response);
    } else {
      if (error.message.includes('expired')) {
        setTimeout(()=>{window.location.reload()}, 2500);
      }
      reject(error);
    }
    
  }
  else
  {
    console.error(error);
    if (error.message.includes('expired')) {
      setTimeout(()=>{window.location.reload()}, 2500);
    }
    reject(error);
  }
}

export const handleResponse = (res, resolve, reject)=>
{
  if(res.status<400)
    resolve(res.data);
  else
    handleError(new Error(`request failed with status code: ${res.status}`), reject)
    
}