import createResourceId from '../utils/createResourceId';
import { sign, JWT_SECRET, JWT_EXPIRES_IN } from '../utils/jwt';
import wait from '../utils/wait';
import { User } from '../types/user';
import { HOST } from 'src/__api__/apiConfig';
import { cleanupLS } from 'src/contexts/JWTContext';

const users = [
  {
    id: '5e86809283e28b96d2d38537',
    avatar: '/static/mock-images/avatars/avatar-jane_rotanson.png',
    email: 'support@durodogs.com',
    name: 'Customer Support',
    password: 'Password123!',
    plan: 'Premium'
  }
];

function reloadPage() {
      let paramsString = window.location.search;
      let searchParams = new URLSearchParams(paramsString);
      let auth = searchParams.get("auth");
      if(!auth || auth === '') {
          window.location.reload();
        } else {
                window.location.href = 'https://www.durodogs.com';
        }
    } 

function navigateToLogin() {
  let paramsString = window.location.search;
      let searchParams = new URLSearchParams(paramsString);
      let auth = searchParams.get("auth");
      if(!auth || auth === '') {
          cleanupLS();
          window.location.href = '/signin';
        } else {
                window.location.href = 'https://www.durodogs.com';
        }
}

class AuthApi {
  async login({ email, password }): Promise<string> {
    await wait(500);

    return new Promise((resolve, reject) => {
      try {
        // Find the user
        const user = users.find((_user) => _user.email === email);

        if (!user || (user.password !== password)) {
          reject(new Error('Please check your email and password'));
          return;
        }

        // Create the access token
        const accessToken = sign(
          { userId: user.id },
          JWT_SECRET,
          { expiresIn: JWT_EXPIRES_IN }
        );

        resolve(accessToken);
      } catch (err) {
        console.error('[Auth Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  async register({ email, name, password }): Promise<string> {
    // await wait(1000);

    return new Promise((resolve, reject) => {
      try {
        // Check if a user already exists
        let user = users.find((_user) => _user.email === email);

        if (user) {
          reject(new Error('User already exists'));
          return;
        }

        user = {
          id: createResourceId(),
          avatar: null,
          email,
          name,
          password,
          plan: 'Standard'
        };

        users.push(user);

        const accessToken = sign(
          { userId: user.id },
          JWT_SECRET,
          { expiresIn: JWT_EXPIRES_IN }
        );

        resolve(accessToken);
      } catch (err) {
        console.error('[Auth Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  me(accessToken, anonId=undefined, referredBy=undefined, reservedDogId?:string, tutorialState?:string): Promise<User> {
    return new Promise((resolve, reject) => {
      try {
        console.log('updating user');
        let host = HOST;
        let url = `${host}/api/v1/customers/registration`;
        let body = {};
        if (anonId) {
          body['anonId'] = anonId;
        }
        if (referredBy) {
          body['referredBy'] = referredBy;
        }
        if (reservedDogId) {
          body['reservedDogId'] = reservedDogId;
        }
        if(tutorialState) {
          body['tutorialState'] = tutorialState;
        }
        fetch(url,
         {
           method: 'POST',
           body: JSON.stringify(body),
           headers: {
             'Content-Type': 'application/json',
             'Authorization' : `Bearer ${accessToken}`},
         }
        )
        .then(async (res)=> {

          const isJson = res.headers.get('content-type')?.includes('application/json');
          let json = isJson?await res.json():null;

          if (!res.ok) {
            json = await res.text();
            // error handling here
            localStorage.removeItem('accessToken');
            if (json.includes('please login with your handcash account instead')) { 
              reject(new Error(json));
              window.localStorage.setItem('alreadyHandcash', 'true');
              navigateToLogin();
            } else {
              reject(new Error('Invalid authorization token, please log back in'));
              navigateToLogin();
            }
            
            return;
          } else {
                //need to update model
              resolve({
                id: json.handle,
                chosen1stDog: json.chosen1stDog,
                gameTreats: json.gameTreats,
                accessories: json.accessories,
                dogs: json.dogs,
                ...json
              });

          } 
        }).catch(function(error)
          {
            console.log('autherror', error);
            localStorage.removeItem('accessToken');
            reject(new Error('Please log back in'));
            navigateToLogin();
          });

      } catch (err) {
        console.error('[Auth Api]: ', err);
        localStorage.removeItem('accessToken');
        reject(new Error('Internal server error, please log back in'));
        navigateToLogin();
      }
    });
  }
}

export const authApi = new AuthApi();
