import { useEffect, useContext } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Box, Divider, Drawer, Icon, SvgIcon, Typography} from '@mui/material';
import type { Theme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Logo from '../Logo';
import NavSection from '../NavSection';
import useCustomStyles from '../../hooks/useCustomStyles'
import { experimentalStyled, SxProps, useTheme } from '@mui/material/styles';
import AuthContext from 'src/contexts/JWTContext';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SettingsIcon from '@mui/icons-material/Settings';
import ListIcon from '@mui/icons-material/List';
import BookIcon from '@mui/icons-material/Book';
import { vh } from 'src/hooks/useVhUnitWorkaround';
import GavelIcon from '@mui/icons-material/Gavel';
import LockIcon from '@mui/icons-material/Lock';



interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const ExitToAppIcon = (props)=><SvgIcon {...props }>
<path d="M10.09 15.59L11.5 17l5-5-5-5-1.41 1.41L12.67 11H3v2h9.67l-2.58 2.59zM19 3H5c-1.11 0-2 .9-2 2v4h2V5h14v14H5v-4H3v4c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"/>
</SvgIcon>

export const HelpIcon = (props)=><SvgIcon {...props }>
<path fill={props.color} d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"/>
</SvgIcon>

const InfoIcon = (props)=><SvgIcon {...props }>
<path fill={props.color} d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"/>
</SvgIcon>

const MailIcon = (props)=><SvgIcon {...props }>
<path d="M0 0h24v24H0z" fill="none"/><path fill={props.color} d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"/>
</SvgIcon>







const bottomSections = [
  {
    title: '',
    items: [
      {
        title: 'HOW TO PLAY',
        path: 'https://drive.google.com/file/d/1-gEXtJggQc6MQ9i0S7jb_eOfgJJ-lBfs/view?usp=sharing',
        icon: <InfoIcon color='#fff' />
      },
      {
        title: 'FAQ',
        path: 'https://drive.google.com/file/d/135CWwGCosVC_O5AlZO6GHugT_d8pZVbA/view',
        icon: <HelpIcon color='#fff'/>
      },
      {
        title: 'Terms of Service',
        path: '/ToS',
        icon:  <GavelIcon style={{color: 'white'}}/>
      },
      {
        title: 'Privacy Policy',
        path: '/Privacy',
        icon:  <LockIcon style={{color: 'white'}}/>
      },
      {
        title: 'REPORT A BUG',
        path: 'https://docs.google.com/forms/d/e/1FAIpQLSdvNU0ELvjW0as8mjWWeE76lj6oQA8rF_9ivzKsPgttqWG2ww/viewform?usp=sf_link',
        icon:  <MailIcon color='#fff'/>
      },
    ]
  }
];

const MobileDrawer = experimentalStyled(Drawer)(
  ({ theme }) => (
    {
      zIndex: theme.zIndex.drawer + 101,
    }
  )
);


const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const classes = useCustomStyles();
  const theme = useTheme();
  const auth =useContext(AuthContext)
  const shortHeight =  useMediaQuery('(min-height:770px)');
  const shortHeightMobile = useMediaQuery('(min-height:730px)');
  const isMinHeight = lgUp?shortHeight:shortHeightMobile;

  const iconStyling= isMinHeight?{ width: 25, height: 30 }: {width: '1em', height: '1.2em'}
  const buttonBottomStyling:SxProps = {fontSize: isMinHeight?'1rem':vh(2), my: isMinHeight?'1.5em':vh(1), color: theme.palette.primary.light, mb: {xs: isMinHeight?'2em':vh(1), md:isMinHeight?'2em':vh(1)}, backgroundColor: '#fff','&:hover': { backgroundColor: '#eee'}, borderRadius: '0.7em', px: '25%'}

  
 
  const sections = [
    {
      title: '',
      items: [
        {
          title: 'MY BACKYARD',
          path: '/dashboard',
          icon: <Icon sx={iconStyling}><img style={iconStyling} src='/static/icons/menu_dog_icon.svg' alt='backyardIcon'/></Icon>,
          nav: false
        },
        {
          title: 'MY INVENTORY',
          path: '/dashboard/market/inventory',
          icon: <Icon sx={iconStyling}><img  style={iconStyling} src='/static/icons/menu_inventory_icon.svg' alt='backyardIcon'/></Icon>,
          nav: false
        },
        {
          title: 'MARKET (BETA)',
          path: '/dashboard/market',
          nonHandcashPath: '/dashboard/market',
          icon: <AccountBalanceIcon sx={{...iconStyling, color: '#fff'}} />
        },
        {
          title: 'MY LISTINGS',
          path: '/dashboard/market/listings',
          nonHandcashPath: '/dashboard/handcashSignup',
          icon: <ListIcon sx={{...iconStyling, color: '#fff'}} />
        },
        {
          title: 'MARKET HISTORY',
          path: '/dashboard/market/history',
          nonHandcashPath: '/dashboard/handcashSignup',
          icon: <BookIcon sx={{...iconStyling, color: '#fff'}} />
        },
        {
          title: 'REFERRALS',
          path: '/dashboard/ReferralProgram',
          nonHandcashPath: '/dashboard/handcashSignup',
          icon: <Icon  sx={{...iconStyling, color: '#fff'}}><img style={iconStyling} src='/static/icons/ReferralIcon.svg' alt='' /></Icon>
        },
        {
          title: 'BUY BSV',
          path: 'https://app.handcash.io/#/home',
          nonHandcashPath: '/dashboard/handcashSignup',
          icon: <Icon  sx={{...iconStyling, color: '#fff'}}><img style={iconStyling} src='/static/icons/BsvIconWhite.svg' alt='' /></Icon>
        },
        {
          title: 'SETTINGS',
          path: '/dashboard/settings',
          nonHandcashPath: '/dashboard/settings',
          icon: <SettingsIcon sx={{...iconStyling, color: '#fff'}} />
        },
        
      ]
    }
  ];

  async function logoutClicked()
  {
      await auth.logout();
      window.location.href = '/signin';
  }

  function loginClicked() {
    window.location.href = '/signin';
  }



  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  useEffect(()=>{
    if (auth.user && auth.user.tutorialState === undefined) {
      auth.user.tutorialState = 9;
    }
  },[auth.user])

  const content = (
    <Box
    data-testid='dashboardSidebar'
    className={classes.outline}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'auto'
      }}
    >
        <Box sx={{display: 'flex', flexDirection:'column', height: '100%'}}>
         {auth.user && (auth.user.tutorialState === undefined || auth.user.tutorialState >= 9) && <><Box sx={{ px: 2, py: isMinHeight?2:0}}>
            {sections.map((section) => (
              <NavSection
                key={section.title}
                pathname={location.pathname}
                hasHandcash={auth.user.hasHandcash !== false}
                sx={{
                  '& + &': {
                    mt: isMinHeight?3:0
                  }
                }}
                {...section}
              />
            ))}
          </Box>
          <Box sx={{flexGrow: 1}}></Box>
          <Box sx={{ py: isMinHeight?2:0, px: 2}}>
            {bottomSections.map((section) => (
              <NavSection
                key={section.title}
                pathname={location.pathname}
                hasHandcash={auth.user.hasHandcash !== false}
                sx={{
                  '& + &': {
                    mt: isMinHeight?3:0
                  }
                }}
                {...section}
              />
            ))}
          </Box>
          </>}
          {(!auth.user ||( auth.user.tutorialState !== undefined && auth.user.tutorialState < 9)) && <Box sx={{flexGrow: 1}}></Box>}
          {auth.isAuthenticated ? <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <Button variant="contained" onClick={logoutClicked} startIcon={<ExitToAppIcon color="primary"/>} sx={buttonBottomStyling}> SIGN OUT</Button>
          </Box>
          :
          <><Typography sx={{color: 'white', width: '100%', textAlign: 'center'}}>Already have an account?</Typography>
          <Box sx={{display: 'flex', justifyContent: 'center'}}>
            
          <Button variant="contained" onClick={loginClicked} startIcon={<ExitToAppIcon color="primary"/>} sx={buttonBottomStyling}> SIGN IN</Button>
        </Box>
        </>     }
        </Box>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'primary.main',
            height: `calc(100% - ${vh(6.5)}) !important`,
            top:  `${vh(6.5)} !important`,
            width: 280,
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <MobileDrawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'primary.main',
          width: 280,
          height: vh(100),
          top: 0,
        }
      }}
      variant="temporary"
    >
      {content}
    </MobileDrawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
