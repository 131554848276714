
export function isInt(value) {
    return Number.isInteger(value);
  }

export const roundToDecimal = (num, places = 2, method = 'round') => {
  const base10 = 10 ** places;
  return (Math[method](num * base10) / base10);
}

export const sleep = async (ms) => { return new Promise(resolve => setTimeout(resolve, ms)); };

export function reduceSizeWithLength(stdSize: number, maxLength: number, text:string):number {
  if (!text || text.length <= maxLength) {
    return stdSize;
  }
  return (maxLength / text.length) * stdSize;
}


const LS = window.localStorage;
export const LSWrapper = {
  set: (key:string, data:any, format:boolean = true) => (LS.setItem(key, (format) ? JSON.stringify(data) : data)),
  get: (key:string, format:boolean = true) => ((format) ? JSON.parse(LS.getItem(key)) : LS.getItem(key))
};