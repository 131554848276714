import { useEffect, useState } from 'react';

// fix 100vh not taking address bar into account on mobile device
// https://chanind.github.io/javascript/2019/09/28/avoid-100vh-on-mobile-web.html
// 
// TODO: currently only used in playground, need to apply to the whole app
const useVhUnitWorkaround = () => {

  const [windowHeight, setWindowHeight] = useState(window?.innerHeight);
  useEffect(() => {
    document.documentElement.style.setProperty('--vh', `${windowHeight / 100}px`);

    return () => {
      document.documentElement.style.removeProperty('--vh');
    }
  }, [windowHeight]);

  useEffect(() => {
    function handleResize() {
      setWindowHeight(window.innerHeight);
    }

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    }
  }, []);

  return vh;
}

export function vh(height: number):string {
  return `calc(var(--vh, 1vh) * ${height})`;
}

export default useVhUnitWorkaround;
