import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'nprogress/nprogress.css';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import App from './App';
import { AuthProvider } from './contexts/JWTContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { DataProvider } from './contexts/DataContext';

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
        <StyledEngineProvider injectFirst>
            <SettingsProvider>
              <BrowserRouter>
                <AuthProvider>
                  <DataProvider>
                    <App />
                  </DataProvider>
                </AuthProvider>
              </BrowserRouter>
            </SettingsProvider>
        </StyledEngineProvider>
    </HelmetProvider>
  </StrictMode>,
  document.getElementById('root')
);
