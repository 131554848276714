import { FC, useContext, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Box, Button, IconButton, Stack, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import { experimentalStyled } from '@mui/material/styles';
import type { AppBarProps } from '@mui/material';
import MenuIcon from '../../icons/Menu';
import Logo from '../Logo';
import useCustomStyles from '../../hooks/useCustomStyles'
import NotificationsPopover from './NotificationsPopover';
import AuthContext from 'src/contexts/JWTContext';
import { vh } from '../../hooks/useVhUnitWorkaround';

interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
}

const DashboardNavbarRoot = experimentalStyled(AppBar)(

 
  ({ theme }) => (
    {
      ...(
        theme.palette.mode === 'light' && {
          backgroundColor: theme.palette.primary.main,
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          color: theme.palette.primary.contrastText
        }
      ),
      ...(
        theme.palette.mode === 'dark' && {
          backgroundColor: theme.palette.background.paper,
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        }
      ),
      zIndex: theme.zIndex.drawer + 100,
      height: vh(6.5),
      [theme.breakpoints.down('lg')]: {
        'body[playground] &': {
          display: 'none'
        },
      },
    }
  )
);

const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const { onSidebarMobileOpen, ...other } = props;
  const classes = useCustomStyles();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('md'));
  const marketSidebarShown = useMediaQuery(theme.breakpoints.up('lg'));
  const [marketPage, setMarketPage] = useState(null);

  useEffect(()=>{
    if (window.location.href.includes('market/inventory')) {
      setMarketPage('My Inventory');
      return;
    }
    if(window.location.href.includes('market/listings'))  {
      setMarketPage('My Listings');
      return;
    }
    if(window.location.href.includes('/market/history')) {
      setMarketPage('Market History');
      return;
    }
    if(window.location.href.includes('/market')) {
      setMarketPage('Duro Dogs Market');
      return;
    }
    
    setMarketPage(null);
  },[window.location.href])


  return (
    <DashboardNavbarRoot>
        <Stack direction='row' gap={isMobileDevice?0:5} alignItems='center' justifyContent={isMobileDevice?'space-between':'flex-start'} sx={{
          width: '100%',
          height: vh(6.5),
          border: {xs: 'none', md: '2px solid white'},
          px: isMobileDevice?'3vw':'1vw',
          }}>
        {!!(auth.user && auth.user?.tutorialState > 8) && <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
          sx={{
            height: vh(6.5), 
            width:  vh(6.5),
            display: {
              lg: 'none'
            }
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>}
          {marketPage && !marketSidebarShown?
          <Typography sx={{color: '#fff', fontSize: {xs: vh(1.7), sm: vh(2)}, width: '100%', textAlign: 'left'}}>{marketPage}</Typography>
          :
          <img onClick={()=>{navigate('/')}} style={{width: isMobileDevice?'25%':undefined, height: isMobileDevice?'undefined':vh(3.8)}} src={'/static/images/logos/Duro-Dogs-Logo.svg'} alt='' />}
        {(!auth.user || auth.user.tutorialState < 9) &&
        <Typography sx={{
          textAlign: 'center', 
          textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          fontWeight: 'bold', 
          fontSize: isMobileDevice?'3.34vw':vh(2)
        }}>Play, Train, Earn</Typography>
        }
        <Box sx={{flexGrow: 1, display: isMobileDevice?'none':'block'}}></Box>
        
          {!auth.isAuthenticated && !auth.user ? <Button onClick={()=>{
            navigate('/signin');
          }} sx={{textDecoration: 'underline', color: 'white', fontSize: {xs: '3.34vw', sm: '3.34vw', md: vh(1.7)} , display: {lg: 'none'}}}>SIGN IN</Button>
          :
          <Box sx={{width: '20%', display: isMobileDevice?'block':'none'}}></Box>
          }
          {((auth.user && auth.isAuthenticated && (!auth.user?.tutorialState && auth.user?.tutorialState !== 0)) || auth.user?.tutorialState > 8 ) && <NotificationsPopover />}
        </Stack>
        </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default DashboardNavbar;
