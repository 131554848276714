import type { FC } from 'react';
import type { Theme } from '@mui/material';
import { experimentalStyled } from '@mui/material/styles';
import type { SxProps } from '@mui/system';

interface LogoProps {
  sx?: SxProps<Theme>;
}

const LogoRoot = experimentalStyled('svg')``;

const Logo: FC<LogoProps> = (props) => (
  <img src={'/static/images/logos/Duro-Dogs-Logo.svg'} alt='' />
 /* <LogoRoot
    height="72"
    version="1.1"
    viewBox="0 0 64.51 8.28"
    {...props}
  >
    <title>MKP</title>
    <defs>
      <filter id="filter">
        <feColorMatrix
          in="SourceGraphic"
          type="matrix"
          values="0 0 0 0 0.262745 0 0 0 0 0.329412 0 0 0 0 0.866667 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g
      fill="none"
      stroke="none"
    >
      <g filter="url(#filter)">
        <g>
          <rect
            height="72"
            x="0"
            y="4.26325641e-14"
          />
          <path
            fillRule="nonzero"
            fill="#ffffff"
            d="M0,0.14h2.22c0.69,0,1.31,0.1,1.87,0.3s1.04,0.48,1.44,0.83c0.4,0.36,0.71,0.78,0.92,1.27c0.22,0.49,0.32,1.02,0.32,1.6c0,0.58-0.11,1.11-0.32,1.6S5.93,6.65,5.53,7c-0.4,0.36-0.88,0.63-1.44,0.83c-0.56,0.2-1.18,0.3-1.87,0.3H0V0.14z M2.22,7.25c0.48,0,0.93-0.07,1.35-0.21C3.99,6.9,4.36,6.7,4.67,6.43c0.31-0.26,0.56-0.59,0.74-0.97c0.18-0.38,0.27-0.82,0.27-1.32c0-0.49-0.09-0.93-0.27-1.31C5.23,2.44,4.98,2.11,4.67,1.85c-0.31-0.26-0.68-0.47-1.1-0.61C3.15,1.1,2.7,1.03,2.22,1.03H1.1v6.22H2.22z"
          />
          <path
            fillRule="nonzero"
            fill="#ffffff"
            d="M13.31,0.14h1.1V5.3c0,0.42-0.07,0.81-0.2,1.17c-0.14,0.36-0.34,0.68-0.6,0.95s-0.59,0.48-0.98,0.63c-0.39,0.15-0.84,0.23-1.35,0.23c-0.51,0-0.96-0.08-1.35-0.23S9.22,7.69,8.95,7.42c-0.26-0.27-0.46-0.58-0.6-0.95c-0.14-0.36-0.2-0.75-0.2-1.17V0.14h1.1v5.21c0,0.6,0.17,1.09,0.51,1.46s0.85,0.56,1.52,0.56s1.18-0.19,1.52-0.56c0.34-0.38,0.51-0.86,0.51-1.46V0.14z"
          />
          <path
            fillRule="nonzero"
            fill="#ffffff"
            d="M17.39,8.14h-1.1V0.14h2.08c0.43,0,0.83,0.05,1.21,0.14c0.37,0.1,0.7,0.24,0.98,0.43c0.28,0.19,0.5,0.42,0.66,0.71c0.16,0.28,0.24,0.61,0.24,0.98c0,0.55-0.18,1.03-0.53,1.44c-0.36,0.41-0.85,0.7-1.49,0.89l2.82,3.41h-1.36l-2.6-3.22c-0.04,0-0.1,0-0.18,0.01s-0.16,0.01-0.25,0.01c-0.09,0-0.17,0-0.26,0s-0.15,0-0.2,0V8.14z M20.34,2.42c0-0.25-0.05-0.46-0.16-0.64s-0.25-0.32-0.44-0.43s-0.4-0.19-0.64-0.24c-0.24-0.05-0.49-0.08-0.76-0.08h-0.96v3.01h0.55c0.34,0,0.65-0.03,0.94-0.1s0.55-0.16,0.76-0.29c0.22-0.13,0.39-0.3,0.51-0.5C20.28,2.95,20.34,2.7,20.34,2.42z"
          />
          <path
            fillRule="nonzero"
            fill="#ffffff"
            d="M26.69,8.28c-0.62,0-1.18-0.11-1.67-0.34c-0.49-0.22-0.9-0.52-1.22-0.9s-0.58-0.82-0.75-1.32s-0.26-1.03-0.26-1.58c0-0.55,0.09-1.08,0.26-1.58s0.42-0.94,0.75-1.32s0.74-0.68,1.22-0.9C25.51,0.11,26.06,0,26.69,0s1.18,0.11,1.67,0.34c0.49,0.22,0.9,0.52,1.22,0.9s0.58,0.82,0.75,1.32c0.17,0.5,0.26,1.03,0.26,1.58c0,0.55-0.09,1.08-0.26,1.58c-0.17,0.5-0.42,0.94-0.75,1.32s-0.74,0.68-1.22,0.9C27.87,8.17,27.31,8.28,26.69,8.28z M26.69,7.39c0.46,0,0.86-0.08,1.21-0.25s0.64-0.4,0.88-0.7s0.41-0.64,0.53-1.04c0.12-0.4,0.18-0.82,0.18-1.27c0-0.45-0.06-0.87-0.18-1.27c-0.12-0.4-0.3-0.74-0.53-1.03s-0.52-0.52-0.88-0.7c-0.35-0.17-0.76-0.26-1.21-0.26s-0.86,0.09-1.21,0.26c-0.35,0.17-0.64,0.4-0.88,0.7s-0.41,0.64-0.53,1.03c-0.12,0.4-0.18,0.82-0.18,1.27c0,0.45,0.06,0.87,0.18,1.27c0.12,0.4,0.3,0.74,0.53,1.04s0.52,0.53,0.88,0.7S26.23,7.39,26.69,7.39z"
          />
          <path
            fillRule="nonzero"
            fill="#ffffff"
            d="M34.67,0.14h2.22c0.69,0,1.31,0.1,1.87,0.3s1.04,0.48,1.44,0.83c0.4,0.36,0.71,0.78,0.92,1.27c0.22,0.49,0.32,1.02,0.32,1.6c0,0.58-0.11,1.11-0.32,1.6S40.6,6.65,40.2,7c-0.4,0.36-0.88,0.63-1.44,0.83c-0.56,0.2-1.18,0.3-1.87,0.3h-2.22V0.14z M36.89,7.25c0.48,0,0.93-0.07,1.35-0.21c0.42-0.14,0.79-0.34,1.1-0.61c0.31-0.26,0.56-0.59,0.74-0.97c0.18-0.38,0.27-0.82,0.27-1.32c0-0.49-0.09-0.93-0.27-1.31c-0.18-0.39-0.43-0.71-0.74-0.98c-0.31-0.26-0.68-0.47-1.1-0.61c-0.42-0.14-0.87-0.21-1.35-0.21h-1.12v6.22H36.89z"
          />
          <path
            fillRule="nonzero"
            fill="#ffffff"
            d="M46.38,8.28c-0.62,0-1.18-0.11-1.67-0.34c-0.49-0.22-0.9-0.52-1.22-0.9s-0.58-0.82-0.75-1.32s-0.26-1.03-0.26-1.58c0-0.55,0.09-1.08,0.26-1.58s0.42-0.94,0.75-1.32s0.74-0.68,1.22-0.9C45.2,0.11,45.76,0,46.38,0s1.18,0.11,1.67,0.34c0.49,0.22,0.9,0.52,1.22,0.9s0.58,0.82,0.75,1.32c0.17,0.5,0.26,1.03,0.26,1.58c0,0.55-0.09,1.08-0.26,1.58c-0.17,0.5-0.42,0.94-0.75,1.32s-0.74,0.68-1.22,0.9C47.56,8.17,47,8.28,46.38,8.28z M46.38,7.39c0.46,0,0.86-0.08,1.21-0.25s0.64-0.4,0.88-0.7S48.88,5.8,49,5.41c0.12-0.4,0.18-0.82,0.18-1.27c0-0.45-0.06-0.87-0.18-1.27c-0.12-0.4-0.3-0.74-0.53-1.03s-0.52-0.52-0.88-0.7c-0.35-0.17-0.76-0.26-1.21-0.26s-0.86,0.09-1.21,0.26c-0.35,0.17-0.64,0.4-0.88,0.7s-0.41,0.64-0.53,1.03c-0.12,0.4-0.18,0.82-0.18,1.27c0,0.45,0.06,0.87,0.18,1.27c0.12,0.4,0.3,0.74,0.53,1.04s0.52,0.53,0.88,0.7S45.92,7.39,46.38,7.39z"
          />
          <path
            fillRule="nonzero"
            fill="#ffffff"
            d="M55.62,0c0.45,0,0.88,0.04,1.28,0.11s0.78,0.19,1.1,0.35l-0.18,0.88c-0.34-0.14-0.7-0.24-1.07-0.32c-0.37-0.08-0.75-0.12-1.12-0.12c-0.99,0-1.77,0.29-2.34,0.87c-0.57,0.58-0.85,1.4-0.85,2.46c0,1.02,0.27,1.81,0.8,2.35c0.54,0.55,1.34,0.82,2.41,0.82c0.26,0,0.51-0.02,0.77-0.05c0.26-0.04,0.48-0.08,0.67-0.14v-2.6h1.01v3.22c-0.3,0.13-0.69,0.24-1.16,0.32C56.47,8.24,56,8.28,55.52,8.28c-0.66,0-1.24-0.1-1.76-0.29s-0.96-0.47-1.32-0.83c-0.36-0.36-0.63-0.78-0.82-1.28s-0.28-1.04-0.28-1.64c0-0.62,0.1-1.2,0.29-1.72c0.2-0.52,0.48-0.97,0.85-1.34c0.37-0.37,0.82-0.66,1.34-0.87C54.35,0.1,54.95,0,55.62,0z"
          />
          <path
            fillRule="nonzero"
            fill="#ffffff"
            d="M64.51,6.06c0,0.34-0.07,0.65-0.22,0.92c-0.15,0.27-0.35,0.5-0.6,0.7s-0.55,0.34-0.89,0.44c-0.34,0.1-0.71,0.16-1.1,0.16c-0.38,0-0.78-0.04-1.18-0.12c-0.4-0.08-0.77-0.2-1.11-0.36l0.22-0.88c0.3,0.14,0.62,0.25,0.98,0.34s0.71,0.13,1.07,0.13c0.52,0,0.94-0.11,1.26-0.33c0.32-0.22,0.48-0.54,0.48-0.95c0-0.23-0.05-0.43-0.14-0.59c-0.09-0.16-0.22-0.31-0.38-0.44c-0.16-0.13-0.35-0.25-0.56-0.35s-0.44-0.21-0.68-0.32c-0.27-0.13-0.53-0.26-0.79-0.4c-0.25-0.14-0.47-0.3-0.67-0.48s-0.35-0.38-0.46-0.61c-0.12-0.23-0.17-0.49-0.17-0.8c0-0.34,0.06-0.65,0.19-0.91c0.12-0.26,0.3-0.48,0.52-0.66c0.22-0.18,0.49-0.31,0.8-0.4S61.75,0,62.12,0c0.38,0,0.76,0.04,1.12,0.13c0.36,0.09,0.68,0.2,0.95,0.35L64,1.36c-0.26-0.14-0.55-0.25-0.88-0.34c-0.33-0.09-0.66-0.13-1-0.13c-0.97,0-1.45,0.38-1.45,1.13c0,0.17,0.02,0.31,0.07,0.44c0.05,0.12,0.13,0.24,0.23,0.35c0.11,0.11,0.25,0.21,0.43,0.31c0.18,0.1,0.4,0.21,0.65,0.34c0.26,0.13,0.53,0.27,0.82,0.41c0.29,0.15,0.55,0.32,0.79,0.53c0.24,0.2,0.44,0.44,0.6,0.71C64.43,5.38,64.51,5.7,64.51,6.06z"
          />
        </g>
      </g>
    </g>
  </LogoRoot>*/
);

export default Logo;
