import { Analytics, AnalyticsBrowser } from '@segment/analytics-next'

let analyticsInstance = null;

export async function trackEvent(event: string, analytics?:Analytics, parameters?: Object) {
    try {
        if (analytics) {
            analyticsInstance = analytics;
        }
        if(!analyticsInstance) {
            const writeKey = 'qSdAUrKOnmqIPuA9mjUT0GsDSBkllp0K';
            let [analytics] = await AnalyticsBrowser.load({ writeKey });
            analyticsInstance = analytics;
        } 
        if (parameters) {
            await analyticsInstance.track(event, parameters);
        } else {
            await analyticsInstance.track(event);
        }
    } catch(e) {
        console.log('analytics failed');
    }
}

export function setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

export function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}