import { Box, IconButton, Input, Stack, Typography } from "@mui/material";
import { useState, useEffect } from "react";
const Joi = require('joi');

export const InputLabel = ({ children, active= true,  color='white', bgColor='#0F79E7' })=>{
    return (
        <>
        {active && <Box sx={{position: 'absolute', backgroundColor: bgColor, px: '0.5em',  top: '0.3em', left: '0.5em'}}>
            <Typography sx={{color: color, fontWeight: 'bold', fontSize: '0.6em'}}>{children}</Typography> 
        </Box>}
        
        </>
    );
}

export const PasswordHelpHover = ({ questionmarkColor = 'white'})=>{
    const [showHover, setShowHover] = useState(false);
    return (
        <>
            <Box>
                <IconButton onClick={()=>{setShowHover(!showHover);}} onMouseEnter={()=>{setShowHover(true);}} onMouseLeave={()=>{setShowHover(false);}} sx={{position: 'absolute', zIndex: 4,  top: '0.5em', right: '0.25em'}}><img style={{height:'1em'}} src={questionmarkColor==='white'?'/static/icons/questionmark.svg':'/static/icons/questionmarkBlack.svg'} alt='' /></IconButton>
                {showHover && <Stack gap={2} alignItems='center' sx={{backgroundColor:'#2E2E2E', width: '130%', position: 'absolute', padding: {xs: '1.5em', md: '1.5em'}, left: '-30%', zIndex: 3, top: {xs: '3em', md: '3em'}, color: 'white', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'}} >
                    <Typography sx={{fontSize: {xs: '0.7em', md: '1em'}}}>Your password must have 7+ characters</Typography>
                    <Typography sx={{fontSize: {xs: '0.7em', md: '1em'}}}>Your password must have at least 1 number</Typography>
                    <Typography sx={{fontSize: {xs: '0.7em' , md: '1em'}}}>Your password must contain no space(s)</Typography>
                </Stack>}
           </Box> 
        </>
    );
}

const PasswordInput = ({ onValidInput, showEmail = true, showConfirm = true, showPassword= true, color='white', bgColor='#3a6ccb', showError=true, showHelp= true }) => {

    const [password, setPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [validPassword, setValidPassword] = useState(false);
    const [validEmail, setValidEmail] = useState(false);
    const [email, setEmail] = useState(null);
    const [error, setError] = useState(null);
    
    const [passwordSelected, setPasswordSelected] = useState(false);
    const [emailSelected, setEmailSelected] = useState(false);
    const [passwordConfirmSelected, setPasswordConfirmSelected] = useState(false);

    const passwordSchema = Joi.object({
        password: Joi.string().regex(/^\S*(?=.{7,})(?=.*\d)(?=.*[a-zA-Z]).*$/).min(7).max(55).alphanum()
        .required(),
      });

    const emailSchema =  Joi.object({
        email: Joi.string().email({ minDomainSegments: 2, tlds: {allow: false} }).required(),
      });

    function validateEmail() {
        const { error, value }  =  emailSchema.validate({ email });
        if(error && email && email.length > 0) {
            setError('enter a valid email');
            setValidEmail(false);
            return false;
        } else {
            setValidEmail(true);
            setError(null);
            return true;
        }
    }

    function validatePassword() {
        const { error, value }  =  passwordSchema.validate({ password });
        if(error && password && password.length > 0) {
            setError('Password must be 7 characters or more and contain a number');
            setValidPassword(false);
            return false;
        } else {
            setError(null);
            setValidPassword(true);
            return true;
        }
    }

    useEffect(()=>{
    }, [onValidInput])

    
      

    useEffect(()=>{
        if(!onValidInput) {
            return;
        }
        if ((!showPassword || (validatePassword() && (!showConfirm || confirmPassword === password))) && (!showEmail || (validateEmail()))) {
            onValidInput(password, email);
        } else {
            if (password !== confirmPassword) {
                if(!error || error === 'confirm Password does not match Password') {
                    if(validPassword && validEmail) {
                        setError('confirm Password does not match Password');
                    }
                }
            } else {
                if (error === 'confirm Password does not match Password') {
                    setError(null);
                }
            }
            onValidInput(null, null);
        }
    }, [password, confirmPassword, email])
    return (
        <>
            <Box sx={{width: '100%', color: color}}>
            {showEmail && <Stack gap={0} direction='row' sx={{position: 'relative'}} alignItems='center'><Input onBlur={()=>{setEmailSelected(false)}} onFocus={()=>{setEmailSelected(true)}} sx={{my: '0.5em', '& .MuiInput-input':{

                border: '1px solid ' + color, color: color, borderRadius: '0.3em',fontSize: {xs: '3.5vw', md: '1rem'}, padding: '1em', backgroundColor: 'rgba(255, 255, 255, 0)',

            }}} fullWidth={true} disableUnderline={true} placeholder='Email' onChange={(event)=>{setEmail(event.target.value)}}></Input><InputLabel color={color} bgColor={bgColor} active={emailSelected}>Email</InputLabel></Stack>}
            {showPassword && <Stack gap={0} direction='row' sx={{position: 'relative'}} alignItems='center'><Input onBlur={()=>{setPasswordSelected(false)}} onFocus={()=>{setPasswordSelected(true)}} sx={{my: '0.7em','& .MuiInput-input':{

                border: '1px solid ' + color, color: color, fontSize: {xs: '3.5vw', md: '1rem'}, borderRadius: '0.3em', padding: '1em', backgroundColor: 'rgba(255, 255, 255, 0)'

            }}} fullWidth={true}  disableUnderline={true} placeholder='Password' type='password' defaultValue={password} value={password} onChange={(event)=>{event.preventDefault(); setPassword(event.target.value)}}></Input><InputLabel color={color} bgColor={bgColor} active={passwordSelected}>Password</InputLabel>{showHelp && <PasswordHelpHover questionmarkColor={color} />}</Stack>}
            {showConfirm && <Stack gap={0} direction='row' alignItems='center' sx={{position: 'relative'}}><Input onBlur={()=>{setPasswordConfirmSelected(false)}} onFocus={()=>{setPasswordConfirmSelected(true)}} sx={{my: '0.5em','& .MuiInput-input':{

                border: '1px solid ' + color, color: color, borderRadius: '0.3em', fontSize: {xs: '3.5vw', md: '1rem'},  padding: '1em', backgroundColor: 'rgba(255, 255, 255, 0)'

            }}} fullWidth={true}  disableUnderline={true} placeholder='Confirm Password' type='password' defaultValue={confirmPassword} value={confirmPassword} onChange={(event)=>{event.preventDefault(); setConfirmPassword(event.target.value)}}></Input><InputLabel color={color} bgColor={bgColor}  active={passwordConfirmSelected}>Confirm Password</InputLabel></Stack>}
            </Box>
            {error && showError && <Typography sx={{fontSize: {xs: '2vw', textAlign: 'center', md: '0.8rem'}, color: 'red'}}>{String(error)}</Typography>}
        </>
    );
}

export default PasswordInput;