import { createTheme } from '@mui/material/styles';

const theme = createTheme({

  palette:{
    primary:{
      main:'#0F7AE8',
      dark:'#273860'
    },
    secondary:{
      main:'#3B984D'
    }
  }
})

export default theme;