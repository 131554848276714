import { API_VERSION, HOST, handleError, handleResponse, checkAuth } from './apiConfig';
import type { Dog, dogBodyParts, DogItem } from '../types/dog';
import axios from 'axios';


class DogApi {

  /*
    returns Array of all user owned Dogs
  */
  getDogs(): Promise<Dog[]> {
    const axiosAuthConfig = checkAuth();
    return new Promise<Dog[]>((resolve, reject) => {
    
    const requestURL:string = HOST + API_VERSION + '/dogs';
    axios.get(requestURL, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject)).catch((err)=>handleError(err, reject));
    });
  }

  getDogDetails(nftIdArr: string[], signal?: any): Promise<Dog[]> {
    const axiosAuthConfig = checkAuth();
     // Create a CancelToken
    const source = axios.CancelToken.source();
    if (signal) {
      // Bind the signal to cancel the source when the signal is aborted
      signal.onabort = () => source.cancel('Operation canceled by the user.');

    }
    return new Promise<Dog[]>((resolve, reject) => {
    const body = { nftIdArr };
    if (!nftIdArr || nftIdArr.length === 0) {
      return [];
    }
    const requestURL:string = HOST + API_VERSION + '/dogs/dogDetails';
    axios.post(requestURL, body, {...axiosAuthConfig, cancelToken: source.token})
      .then((res) => handleResponse(res, resolve, reject))
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log('Request canceled', err.message);
        } else {
          handleError(err, reject);
        }
      });
  });
}
  /*
    returns the dog requsted by the id, if the dog is not owned by the user a 404 not found error will be returned.
  */
  getDog(dogId:string): Promise<Dog> {
    const axiosAuthConfig = checkAuth();
    
    return new Promise<Dog>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/dogs/' + dogId;
      axios.get(requestURL, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject)).catch((err)=>handleError(err, reject));
      });
  }

    /*
    returns the dog requsted by the id, if the dog is not owned by the user a 404 not found error will be returned.
  */
    getDogByNftId(nftId:string): Promise<Dog> {
      const axiosAuthConfig = checkAuth();
      
      return new Promise<Dog>((resolve, reject) => {
        const requestURL:string = HOST + API_VERSION + '/dogs/nftId/' + nftId;
        axios.get(requestURL, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject)).catch((err)=>handleError(err, reject));
        });
    }

  /*
      buys a new Dog for 1.99USD,
  */
  buyDog(dogName:string):Promise<Dog>{
    const axiosAuthConfig = checkAuth();
    return new Promise<Dog>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/dogs/buy';
      const params = {dogName: dogName};
      axios.post(requestURL, params, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject )).catch((err)=>handleError(err, reject));
      });
  }

  /*
      adops a new Dog for 1.99USD,
  */
      adoptDog(dogId:string):Promise<Boolean>{
        const axiosAuthConfig = checkAuth();
        return new Promise<Boolean>((resolve, reject) => {
          const requestURL:string = HOST + API_VERSION + '/dogs/adopt';
          const params = {dogId};
          axios.post(requestURL, params, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject )).catch((err)=>handleError(err, reject));
          });
      }

  /*
      creates a new Dog
  */
  createDog(dogName:string):Promise<Dog>{
    const axiosAuthConfig = checkAuth();
    return new Promise<Dog>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/dogs/create';
      const params = {dogName: dogName};
      axios.post(requestURL, params, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject )).catch((err)=>handleError(err, reject));
      });
  }
  /*
    returns new dog name
  */
  renameDog(newDogName:string, dogId:string):Promise<string>{
    const axiosAuthConfig = checkAuth();
    return new Promise<string>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/dogs/rename/' + dogId;
      const params = {newName: newDogName};
      axios.post(requestURL, params, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject )).catch((err)=>handleError(err, reject));
      });
  }

  reserveDog(anonId: string):Promise<Dog>{
    const axiosAuthConfig = { 
      headers: {
      }
    };
    return new Promise<Dog>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/dogs/reserveDog';
      const params = { anonId };
      axios.post(requestURL, params, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject )).catch((err)=>handleError(err, reject));
      });
  }

  renameReservedDog(dogId: string, newName: string, anonId: string):Promise<Dog>{
    const axiosAuthConfig = { 
      headers: {
      }
    };
    return new Promise<Dog>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/dogs/renameReservedDog';
      const params = {dogId, newName, anonId };
      axios.post(requestURL, params, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject )).catch((err)=>handleError(err, reject));
      });
  }

  equipItem(dogId:string, dogItem:DogItem):Promise<Dog>{
    const axiosAuthConfig = checkAuth();
    return new Promise<Dog>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/dogs/equip/' + dogId;
      const params = dogItem;
      axios.post(requestURL, params, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject )).catch((err)=>handleError(err, reject));
      });
  }

  unequipItem(dogId:string, dogItem:(DogItem | any /* due to some items potentially containing numbers still */)):Promise<Dog>{
    const axiosAuthConfig = checkAuth();
    return new Promise<Dog>((resolve, reject) => {
      const convertedDogItem = {...dogItem};
      if (convertedDogItem.number !== undefined) {
        convertedDogItem.serial = convertedDogItem.number;
        convertedDogItem.number = undefined;
      }
      const requestURL:string = HOST + API_VERSION + '/dogs/unequip/' + dogId;
      const params = convertedDogItem;
      axios.post(requestURL, params, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject )).catch((err)=>handleError(err, reject));
      });
  }

  sendDog(dogId:string, toHandle:string):Promise<Boolean>{
    const axiosAuthConfig = checkAuth();
    return new Promise<Boolean>((resolve, reject) => {
      
      const requestURL:string = HOST + API_VERSION + '/dogs/send/';
      const params = {dogId, toHandle};
      axios.post(requestURL, params, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject )).catch((err)=>handleError(err, reject));
      });
  }

  getAnonDog(serial: number):Promise<any> {
    const axiosAuthConfig = checkAuth();
    
    return new Promise<any>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/dogs/anonDog/' + serial;
      axios.get(requestURL, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject)).catch((err)=>handleError(err, reject));
      });
  }
}

export const dogApi = new DogApi();
