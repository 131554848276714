export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  NATURE: 'NATURE'
};

export const BACKGROUND_THEME = 'Winter';

/*
/static/images/backgrounds/Backyard-Background-with-CirclesWidescreen${BACKGROUND_THEME}.svg
/static/images/backgrounds/Backyard-Background-with-Circles${BACKGROUND_THEME}.svg
`/static/images/backgrounds/Backyard-Background-with-CirclesSquare${BACKGROUND_THEME}.svg
/static/images/backgrounds/Backyard-Background-with-Circles-Mobile${BACKGROUND_THEME}.svg

/static/playground/backgroundMobile${BACKGROUND_THEME}.svg
/static/playground/backgroundSquare${BACKGROUND_THEME}.svg
/static/playground/backgroundWide${BACKGROUND_THEME}.svg
/static/playground/background${BACKGROUND_THEME}.svg
*/ 

export const COLORS = {
  PRIMARY_COLORS: {
    ULTRA_RARE: [
      'C3F0DB', 'FF81EA', '63BA97', 'DBB500', 'F5705F',
      '3E1744', 'D6D9AE', 'FADD8D', '688B6A', 'DEEE58',
      '495F76', '4ABBD9', 'F0EBB4', '4A83B8', 'F9DC52',
      '0091A0', '8C76A4', '4A83B8', 'B3CB8D', '949398',
    ],
    RARE: [
      '688B6A', '498EC5', '8C76A4', 'A8BFE8', 'F78748'
    ],
    COMMON: [
      'B28860', '443F3F', 'A5A3A3', '48341F', '9C8271',
      'FFE3B4', 'ECECEC', 'E5CAA7', '8D4600', 'CCB19A'
    ]
  },
  SECONDARY_COLORS: {
    ULTRA_RARE: [
      '495F76', '4ABBD9', 'F0EBB4', '4A83B8', 'F9DC52',
      '0091A0', '8C76A4', '4A83B8', 'B3CB8D', '949398',
      'C3F0DB', 'FF81EA', '63BA97', 'DBB500', 'F5705F',
      '3E1744', 'D6D9AE', 'FADD8D', '688B6A', 'DEEE58',
    ],
    RARE: [
      'FFFFFF', '9D704A', 'FFF1E4', '887665', 'C5BCB3',
      'B99D85', 'FFE1C2', 'E3BD96', 'C1C1C1', 'FFD9B2'
    ],
    COMMON: [
      'FFFFFF', '9D704A', 'FFF1E4', '887665', 'C5BCB3',
      'B99D85', 'FFE1C2', 'E3BD96', 'C1C1C1', 'FFD9B2'
    ]
  },
  EYE_COLORS: {
    ULTRA_RARE: [['02647D', '027D0E'], ['7D4402', '1EDDE4']],
    RARE: ['EE5050', 'E5F142', '1EDDE4'],
    COMMON: ['02647D', '027D0E', '7D4402', '5D5D5D', '7D6802'],
  },
  NOSE_COLOR: {
    RARE: ['3C5D78', '76ACD8'],
    COMMON: ['77787B', '7F4A28', '4E3D32', 'C8C8C8'],
  },
};


