import { useState, useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Button, Collapse, ListItem, Theme, useMediaQuery } from '@mui/material';
import type { ListItemProps } from '@mui/material';
import ChevronDownIcon from '../icons/ChevronDown';
import ChevronRightIcon from '../icons/ChevronRight';

interface NavItemProps extends ListItemProps {
  title: string;
  depth: number;
  active?: boolean;
  children?: ReactNode;
  icon?: ReactNode;
  info?: ReactNode;
  open?: boolean;
  path?: string;
  nav?: boolean;
}

const NavItem: FC<NavItemProps> = (props) => {
  const {
    active,
    children,
    depth,
    icon,
    info,
    open: openProp,
    path,
    title,
    nav,
    ...other
  } = props;
  const [open, setOpen] = useState<boolean>(openProp);
  const [isURL, setIsURL] = useState<boolean>(false);
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const shortHeight =  useMediaQuery('(min-height:770px)');
  const shortHeightMobile = useMediaQuery('(min-height:730px)');
  const isMinHeight = lgUp?shortHeight:shortHeightMobile;

  const navigate = useNavigate();

  useEffect(()=>{
    if(path.startsWith('http')) {
      setIsURL(true);
    }
    else
    {
      setIsURL(false);
    }
  }, [path])

  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen);
  };

  function outsideOpen() {
    window.open(path);
  }

  let paddingLeft = 16;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  // Branch
  if (children) {
    return (
      <ListItem
        disableGutters
        sx={{
          display: 'block',
          py: 0
        }}
        {...other}
      >
        <Button
          endIcon={!open ? <ChevronRightIcon fontSize="small" />
            : <ChevronDownIcon fontSize="small" />}
          onClick={handleToggle}
          startIcon={icon}
          sx={{
            color: 'text.secondary',
            fontWeight: 'fontWeightMedium',
            justifyContent: 'flex-start',
            pl: `${paddingLeft}px`,
            pr: '8px',
            py: isMinHeight?'12px':'2vh',
            textAlign: 'left',
            textTransform: 'none',
            width: '100%'
          }}
          variant="text"
        >
          <Box sx={{ flexGrow: 1 }}>
            {title}
          </Box>
          {info}
        </Button>
        <Collapse in={open}>
          {children}
        </Collapse>
      </ListItem>
    );
  }

  // Leaf
  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        py: 0
      }}
    >
      <Button
        component={(path && !isURL)?RouterLink:undefined}
        onClick={(isURL) ? outsideOpen : ()=>{}}
        startIcon={icon}
        sx={{
          fontSize: isMinHeight?14:'1.8vh',
          color: 'text.secondary',
          fontWeight: 'fontWeightMedium',
          justifyContent: 'flex-start',
          textAlign: 'left',
          pl: `${paddingLeft}px`,
          pr: '8px',
          py: isMinHeight?'12px':'1.8vh',
          textTransform: 'none',
          width: '100%',
          ...(
            active && {
              color: 'primary.main',
              fontWeight: 'fontWeightBold',
              '& svg': {
                color: 'primary.dark'
              }
            }
          )
        }}
        variant="text"
        to={path}
      >
        <Box 
          color="common.white"
          fontWeight="regular"
          sx={{  flexGrow: 1 }}>
          {title}
        </Box>
        {info}
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  icon: PropTypes.node,
  info: PropTypes.node,
  open: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string.isRequired
};

NavItem.defaultProps = {
  active: false,
  open: false
};

export default NavItem;
