import { COLORS } from '../constants';
import { sha256 } from 'js-sha256';


const getTexture = async (sN: number, dogId: string): Promise<any> => {

  return new Promise((resolve, reject)=> {
    const encoder = new TextEncoder();

    let obj = {
      primaryColor: null,
      secondaryColor: null,
      eyeShape: null,
      eyeColor: null,
      tail: null,
      head: null,
      muzzle: null,
      noseShape: null,
      noseColor: null,
      earShape: null,
      body: null,
    };

    let chain = encoder.encode(sN.toString());
    let primaryColorRarity = 0;
    let ultraRarePrimaryColorIdx = 0;
    let chainBuffer;
    let mod;


    for (let el of Object.keys(obj).filter(el => el!=='muzzle')) {

      chainBuffer = sha256.arrayBuffer(chain);
      let uint8 = new Uint8Array(chainBuffer)
      let view = new DataView(chainBuffer);
      mod = view.getUint16(15) % 1000;
      chain = encoder.encode( uint8.toString() );


      if (el==='primaryColor') {
        let idx;
        // console.log('p mod', mod);
        if (mod <= 889) {
          //common
          primaryColorRarity = 0;
          idx = Math.floor(mod / 89);
        } else if (mod <= 989) {
          //rare
          primaryColorRarity = 1;
          idx = Math.floor((mod-890) / 20);
        } else {
          //ultra rare
          primaryColorRarity = 2;
          idx = mod - 990;
          ultraRarePrimaryColorIdx = idx;
        }
        // console.log('primary color rarity', primaryColorRarity);
        // console.log('primary color idx', idx);
        let rarity = primaryColorRarity === 0 ? 'COMMON' : (primaryColorRarity === 1 ? 'RARE' : 'ULTRA_RARE') ;

        obj.primaryColor = COLORS.PRIMARY_COLORS[rarity][idx];
      }

      if (el === 'secondaryColor') {

        // console.log('s mod', mod);

        let ultraRare = 0;
        let permutationIdx = 0;
        let secondaryColorIdx = 0;
        if (primaryColorRarity == 2) {
          //ultra rare from 0 to 19
          ultraRare = 1;
          secondaryColorIdx = 0;// no need for index when ultra rare
          // console.log('SECOND MOD', mod);
          // if larger than 500
          if (mod > 500) {
            ultraRarePrimaryColorIdx += 10; // flipped ones will have +10
          }


        } else {
          //common, rare
          secondaryColorIdx = Math.floor( mod / 100);
        }

        if (ultraRare) { //ultra rare
          //share ultraRarePrimaryColorIdx both cases
          obj.primaryColor = COLORS.PRIMARY_COLORS['ULTRA_RARE'][ultraRarePrimaryColorIdx];
          obj.secondaryColor = COLORS.SECONDARY_COLORS['ULTRA_RARE'][ultraRarePrimaryColorIdx];
        } else { // not rare
          obj.secondaryColor = COLORS.SECONDARY_COLORS['COMMON'][secondaryColorIdx];
        }
      }

      if (el === 'eyeShape') {
        let idx = oneThird(mod);
        // console.log('eye shape index', idx);
        obj.eyeShape = idx;
      }

      if (el === 'eyeColor') {
        let idx;
        let eyeRarity = 0;
        if (mod <= 889) {
          idx = Math.floor( mod / 180 ) ;
        } else if (mod <= 989) {
          eyeRarity = 1;
          idx = Math.floor( (mod - 890 ) / 33.1);
        } else {
          eyeRarity = 2;
          idx = Math.floor( (mod - 990 ) / 5);
        }

        let rarity = eyeRarity === 0 ? 'COMMON' : (eyeRarity === 1 ? 'RARE' : 'ULTRA_RARE');
        obj.eyeColor = COLORS.EYE_COLORS[rarity][idx];
      }

      if (el === 'tail') {
        let idx = oneSixth(mod);
        // 0 - tail 1 no spots
        // 1 - tail 1 spots
        // 2 - tail 2 no spots
        // 3 - tail 2 spots
        // 4 - tail 3 no spots
        // 5 - tail 3 spots
        // console.log('tail indx', idx);
        obj.tail = idx;
      }

      if (el === 'head') {
        let idx = oneSixth(mod);
        let muzzleIdx = Math.floor((idx+1)/2);
        // 0 - head 1 no spots
        // 1 - head 1 spots
        // 2 - head 2 no spots
        // 3 - head 2 spots
        // 4 - head 3 no spots
        // 5 - head 3 spots
        // console.log('head indx', idx);
        // console.log('muzzle idx', muzzleIdx); //muzzle and head goes together

        obj.head = idx;
        obj.muzzle = muzzleIdx;
      }

      if (el === 'noseShape') {
        let idx = oneThird(mod);
        // 3 shapes
        // console.log('nose', idx);
        obj.noseShape = idx;
      }

      if (el === 'noseColor') {
        let idx;
        let noseColorRarity;
        if (mod < 900) {
          noseColorRarity = 0; //commmon
          idx = Math.floor(mod / Math.ceil(900/4));
        } else {
          noseColorRarity = 1 //rare
          idx = Math.floor((mod - 900) / 50);
        }
        // console.log('nose color rarity:', noseColorRarity);
        // console.log('nose idx:', idx);
        let rarity = noseColorRarity ? 'RARE': 'COMMON';
        obj.noseColor = COLORS.NOSE_COLOR[rarity][idx];
      }

      if (el === 'earShape') {
        let idx = oneThird(mod);
        // console.log('ear idx', idx);
        obj.earShape = idx;
      }

      if (el === 'body') {
        let idx = oneSixth(mod);
        // console.log('body idx', idx);
        obj.body = idx;
      }

    }
    // console.log('obj', obj);
    let hasPatch = obj.body % 2;
    let bodyType = Math.floor(obj.body / 2);
    let pColor = obj.primaryColor;
    let sColor = obj.secondaryColor;
    let leftEyeColor = '';
    let rightEyeColor = '';
    let earColor = pColor;

    //for ultra rare
    if (Array.isArray(obj.eyeColor) && obj.eyeColor.length > 1) {
      leftEyeColor = obj.eyeColor[0];
      rightEyeColor = obj.eyeColor[1];
    } else {
      leftEyeColor = obj.eyeColor;
      rightEyeColor = obj.eyeColor;
    }

    if (primaryColorRarity === 2) {
      earColor = COLORS.SECONDARY_COLORS.ULTRA_RARE[ultraRarePrimaryColorIdx];
    }

    let textureSrc = {
      'torso': `/static/texture/torso/torso-${bodyType}-${pColor}${hasPatch? '-'+sColor : ''}.png`,
      // 'chest': `/static/texture/body/${bodyType}/torso_fur/torso_fur-${pColor}-${sColor}${hasPatch? '-P': ''}.png`,
      'chest': `/static/texture/fur/fur-${bodyType}-${sColor}.png`,

      'leg_back_right_up': `/static/texture/leg-upper-back/leg-r-upper-back-${pColor}.png`,
      'leg_back_right_down': `/static/texture/legs/leg-r-lower-back-${bodyType}-${pColor}${hasPatch? '-'+sColor : ''}.png`,

      'leg_back_left_up': `/static/texture/leg-upper-back/leg-l-upper-back-${pColor}.png`,
      'leg_back_left_down': `/static/texture/legs/leg-l-lower-back-${bodyType}-${pColor}${hasPatch? '-'+sColor : ''}.png`,

      'leg_front_right_up': `/static/texture/arms/arm-r-upper-front-${bodyType}-${pColor}${hasPatch? '-'+sColor : ''}.png`,
      'leg_front_right_down': `/static/texture/arms/arm-r-lower-front-${bodyType}-${pColor}${hasPatch? '-'+sColor : ''}.png`,

      'leg_front_left_up': `/static/texture/arms/arm-l-upper-front-${bodyType}-${pColor}${hasPatch? '-'+sColor : ''}.png`,
      'leg_front_left_down': `/static/texture/arms/arm-l-lower-front-${bodyType}-${pColor}${hasPatch? '-'+sColor : ''}.png`,

      'foot_back_right': `/static/texture/feet/feet-r-back-${pColor}.png`,
      'foot_front_right': `/static/texture/feet/feet-r-front-${pColor}.png`,
      'foot_back_left': `/static/texture/feet/foot-l-back-${pColor}.png`,
      'foot_front_left': `/static/texture/feet/foot-l-front-${pColor}.png`,

      //head
      'head': `/static/texture/head/head-${Math.floor(obj.head/2)}-${pColor}-${sColor}${obj.head%2===1?'-P':''}.png`,

      //mouth
      'mouth': `/static/texture/mouth/mouth-${Math.floor(obj.head/2)}-${sColor}.png`,

      //ear
      'ear_left': `/static/texture/ear/ear-l-${obj.earShape}-${earColor}.png`,
      'ear_right': `/static/texture/ear/ear-r-${obj.earShape}-${earColor}.png`,

      //nose
      'nose': `/static/texture/snout/nose-${obj.noseShape}-${obj.noseColor}.png`,

      'snout': `/static/texture/muzzle/muzzle-${Math.floor(obj.head/2)}-${sColor}-${pColor}.png`,

      //eye
      'eye_left': `/static/texture/eye/left-eye-outline-${obj.eyeShape}.png`,
      'eye_right': `/static/texture/eye/right-eye-outline-${obj.eyeShape}.png`,

      'pupil_left': `/static/texture/pupil/left-pupil-${obj.eyeShape}-${leftEyeColor}.png`,
      'pupil_right': `/static/texture/pupil/right-pupil-${obj.eyeShape}-${rightEyeColor}.png`,

      'eyebrow_left': `/static/texture/eyebrow/left-eyebrow-${obj.eyeShape}.png`,
      'eyebrow_right': `/static/texture/eyebrow/right-eyebrow-${obj.eyeShape}.png`,

      //eyelid
      'eyelid_left_sad': `/static/texture/eyelid/eyelids_left_sad-${pColor}.png`,
      'eyelid_left_half': `/static/texture/eyelid/eyelids_left_half-${pColor}.png`,
      'eyelid_left_whole': `/static/texture/eyelid/eyelids_left_closed-${pColor}.png`,
      'eyelid_right_sad': `/static/texture/eyelid/eyelids_right_sad-${pColor}.png`,
      'eyelid_right_half': `/static/texture/eyelid/eyelids_right_half-${pColor}.png`,
      'eyelid_right_whole': `/static/texture/eyelid/eyelids_right_closed-${pColor}.png`,

      //tail
      'tail': `/static/texture/tail/tail-${Math.floor(obj.tail/2)}-${pColor}-${sColor}${obj.tail%2===1?'-P':''}.png`,
    };

    // append dog it to each texture
    // { 'e45sdfwer2fdsfgxcbsadfw': '/static/texture/...', ... }


    resolve(
      Object.fromEntries(
      Object.entries(textureSrc).map(([key, value]) =>
        [`${dogId}-${key}`, value]
      ))
    );
  })
};

function oneThird(mod) {
  // if (mod <= 332) {
  if (mod <= 330) {
    return 0
  // } else if (mod <= 665) {
  } else if (mod <= 663) {
    return 1;
  } else {
    return 2;
  }
}

function oneSixth(mod) {
  if (mod <= 0 + 165) {
    return 0;
  } else if (mod <= 165+165) {
    return 1;
  } else if (mod <= 165+165+166) {
    return 2;
  } else if (mod <= 165+165+166+167) {
    return 3;
  } else if (mod <= 165+165+166+167+167) {
    return 4;
  } else {
    return 5;
  }
}

export default getTexture;
