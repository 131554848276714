import { Suspense, lazy } from 'react';
import { RouteObject } from 'react-router';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Authentication pages

// const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
// const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
// const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));
// const Register = Loadable(lazy(() => import('./pages/authentication/Register')));
// const VerifyCode = Loadable(lazy(() => import('./pages/authentication/VerifyCode')));

// Dashboard pages

const DogList = Loadable(lazy(() => import('./pages/dashboard/DogList')));
const Play = Loadable(lazy(() => import('./pages/dashboard/Play')));
const Market = Loadable(lazy(() => import('./pages/market/Market')));
const InventoryList = Loadable(lazy(() => import('./pages/dashboard/InventoryList')));
const DurodogsCreatorTool = Loadable(lazy(() => import('./pages/creator/DurodogsCreatorTool')));
const Internal = Loadable(lazy(() => import('./pages/creator/Internal')));

const ReferralProgram = Loadable(lazy(() => import('./pages/dashboard/ReferralProgram')));
const HandcashSignupPage = Loadable(lazy(() => import('./pages/dashboard/HandcashSignupPage')));

// Signup pages

const Select = Loadable(lazy(() => import('./pages/signup/Select')));
const Name = Loadable(lazy(() => import('./pages/signup/Name')));

//onboarding pages
const Welcome = Loadable(lazy(() => import('./pages/dashboard/Welcome')));
const SignInPage = Loadable(lazy(() => import('./pages/login/SignInPage')));
const Reset = Loadable(lazy(() => import('./pages/accountless/Reset')));
const Confirm = Loadable(lazy(() => import('./pages/accountless/Confirm')));
const Settings = Loadable(lazy(() => import('./components/accountless/Settings')));




// Error pages

const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

// Other pages

const Home = Loadable(lazy(() => import('./pages/Home')));
const ToS = Loadable(lazy(() => import('./pages/ToS')));
const Privacy = Loadable(lazy(() => import('./pages/Privacy')));
const routes: RouteObject[] = [
  {
    path: '/select',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/select/',
        element: <Select />
      },
      {
        path: '/select/name',
        element: <Name />
      },
    ]
  },
  {
    path: '/dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/dashboard/',
        element: <DogList />
      },
      {
        path: '/dashboard/play',
        children: [
          {
            path: '/dashboard/play/',
            element: <Play />
          },
          {
            path: '/dashboard/play/:dogNftId',
            element: <Play />
          }
        ]
      },
      {
        path: '/dashboard/market',
        children: [
          { path: '/dashboard/market/', element: <Market /> },
          { path: '/dashboard/market/:accessoryId', element: <Market /> },
          // { path: '/dashboard/market/:accessoryId/:number', element: <Market /> },
          
          { path: '/dashboard/market/shop', element: <Market /> },
          { path: '/dashboard/market/shop/:accessoryId', element: <Market /> },
          // { path: '/dashboard/market/shop/:accessoryId/:number', element: <Market /> },

          { path: '/dashboard/market/inventory', element: <Market /> },
          { path: '/dashboard/market/inventory/:accessoryId', element: <Market /> },
          // { path: '/dashboard/market/inventory/:accessoryId/:number', element: <Market /> },

          { path: '/dashboard/market/listings', element: <Market /> },
          { path: '/dashboard/market/listings/:accessoryId', element: <Market /> },
          // { path: '/dashboard/market/listings/:accessoryId/:number', element: <Market /> },

          { path: '/dashboard/market/history', element: <Market /> },
          // { path: '/dashboard/market/history/:invoiceId', element: <Market /> },

          // { path: '/dashboard/market/bazaar', element: <Market /> },
          { path: '/dashboard/market/bazaar/:handle', element: <Market /> },
          { path: '/dashboard/market/bazaar/:handle/:accessoryId', element: <Market /> },
          // { path: '/dashboard/market/bazaar/:handle/:accessoryId/:number', element: <Market /> }
        ]
      },
      {
        path: '/dashboard/inventory',
        children: [
          {
            path: '/dashboard/inventory/',
            element: <InventoryList />
          },
        ]
      },
      {
        path: '/dashboard/creatorTool',
        element: <DurodogsCreatorTool />
      },
      {
        path: '/dashboard/internal',
        element: <Internal />
      },
      {
        path: '/dashboard/referralProgram',
        element: <ReferralProgram />
      },
      {
        path: '/dashboard/handcashSignup',
        element: <HandcashSignupPage />
      },
      {
        path: '/dashboard/settings',
        element: <Settings />
      },
    ]
  },
  { 
    path: '/ToS',
    element: <DashboardLayout />,
    children: [
      {
        path: '/ToS/',
        element: <ToS />
      },
    ]
  },
  { 
    path: '/Privacy',
    element: <DashboardLayout />,
    children: [
      {
        path: '/Privacy/',
        element: <Privacy />
      },
    ]
  },
  { 
    path: '/welcome',
    element: <DashboardLayout />,
    children: [
      {
        path: '/welcome/',
        element: <Welcome />
      },
    ]
  },
  { 
    path: '/automated',
    element:  <MainLayout />,
    children: [
      {
        path: '/automated/reset',
        element: <Reset />
      },
      {
        path: '/automated/confirm',
        element: <Confirm />
      },
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      
      {
        path: '/',
        element: <Home />
      },
      {
        path: '/signin',
        element: <SignInPage />
      },
      {
        path: '/401',
        element: <AuthorizationRequired />
      },
      {
        path: '/404',
        element: <NotFound />
      },
      {
        path: '/500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      },
    ]
  }
];

export default routes;
