import {
  Dialog,
  DialogProps,
  DialogContent,
  Stack,
  Typography,
  alpha,
  Button,
  useMediaQuery,
} from '@mui/material';
import {styled} from '@mui/styles';
import { useEffect, useState } from 'react';

interface PlaygroundMenuErrorProps extends DialogProps{
    error: any;
    iconURL?: string;
  }

  const RoundDialog = styled(Dialog)(() => ({
    '& .MuiDialog-paper': {
      background: 'transparent linear-gradient(180deg, #0F7AE8 0%, #5662B8 100%) 0% 0% no-repeat padding-box;',//'linear-gradient(#1079E7, #5562B9)',
      color: 'white',
      border: '5px solid white',
      borderRadius: '18px',
      padding: '1rem',
    },
    '& .MuiBackdrop-root': {
      backgroundColor: alpha('#fff', 0.61), 
      backdropFilter: 'blur(1px);',    
      },
  }));
  
  const PlaygroundMenuError = ({
    error,
    iconURL,
    children,
    onClose,
    ...props
  }: PlaygroundMenuErrorProps) => {

      function handleClose() {
        setShowHandcash(false);
        onClose(false, null);
      }

      const [showHandcash, setShowHandcash] = useState(false);

      const isMobileDevice = useMediaQuery('(max-width:358px)');

      useEffect(()=>{
        if (error === 'NFTY Jigs rejected your purchase. Please check to make sure you have enough funds in your Handcash account.') {
          setShowHandcash(true);
        }
      }, [error])
    
      return (
        <RoundDialog {...props} sx={{position: 'absolute'}} disablePortal={true} onClose={handleClose}>
            <DialogContent
              sx={{
                width: { xs: 300, sm: 400 },
                maxWidth: '100%',
                textAlign: 'left',
                mx: 'auto',
                overflowY: 'auto',
              }}
            >
              {!showHandcash?<Stack direction='row' gap={1} alignItems='center' justifyContent='center'>
                <Typography variant='h5' sx={{width: '100%', overflowWrap: 'break-word'}}>{error}</Typography>
                {iconURL?<img src={iconURL} alt='errorIcon' style={{height: '1rem', aspectRatio: '1 / 1'}}/>:''}
              </Stack>
              :
              <>
                <Typography component='div' sx={{lineHeight: '19px'}}>Oops! Looks like you need a little more BSV {<img style={{height: '1.3rem',  marginBottom: '-5px'}} src={'/static/icons/BsvIcon.svg'} alt='bsv'/>}  or have reached your HandCash spending limit. </Typography>
                <Typography sx={{my: '1.5rem'}}>Use the links below to top-up your balance or modify your spending limit.</Typography>
                <Button onClick={()=>{window.open('https://app.handcash.io/')}} startIcon={<img style={{height: '1.5rem',marginBottom: '2px', width: '1.5rem'}} src='/static/icons/handcash1024.png' />} sx={{height: '60px', width: '100%', maxWidth: '469px',
                backgroundColor: '#38CB7B', marginLeft: 'auto', marginRight: 'auto',
                 color: 'white', 
                 fontSize: {sx: '1rem', sm: '1.1rem', md: '1.25rem'},
                 textTransform: 'none',
                 '&:hover': {
                  backgroundColor: '#38CB7B',
                  transform: 'scale(1.01)',
                }
              }}> Add Funds</Button>
              <Button onClick={()=>{window.location.href='https://app.handcash.io/#/settings/spendLimits?redirectUrl=' + window.location.href}} style={isMobileDevice?{fontSize: '0.6rem'}:{}} startIcon={<img style={{height: '1.5rem',marginBottom: '2px', width: '1.5rem'}} src='/static/icons/handcash1024.png' />} sx={{height: '60px', width: '100%', maxWidth: '469px',
                backgroundColor: '#38CB7B', marginLeft: 'auto', marginTop: '1.5rem', marginRight: 'auto',
                 color: 'white',
                 textTransform: 'none',
                 '&:hover': {
                  backgroundColor: '#38CB7B',
                  transform: 'scale(1.01)',
                }
              }}> Reset my Spending Limit</Button>
              </>
              }
            </DialogContent>
        </RoundDialog>
      )
    }
    
  export default PlaygroundMenuError;