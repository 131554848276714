import type { Accessory, ShopAccessory, buyItemResponse, MarketInvoice } from '../types/inventory';
import { API_VERSION, HOST, handleError, handleResponse, checkAuth } from './apiConfig';
import axios from 'axios';;




class MarketApi {
  getAccessoryData(): Promise<ShopAccessory[]> {
    const axiosAuthConfig = checkAuth();
    return new Promise<ShopAccessory[]>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/accessories/accessoryData';
      axios.get(requestURL, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject)).catch((err)=>handleError(err, reject));
    });
  }

  // allItems: unfiltered // liveItems: filted for .live // shopItems: filtered for !.disabled //
  getAccessories(type:string='allItems'): Promise<ShopAccessory[]> {
    const axiosAuthConfig = checkAuth();
    return new Promise<ShopAccessory[]>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/accessories/' + type;
      axios.get(requestURL, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject)).catch((err)=>handleError(err, reject));
    });
  }
  
  
  /*
    returns Array of Accessories, which represent all available items top shop
  */
  getShopAccessories(): Promise<ShopAccessory[]> {
    const axiosAuthConfig = checkAuth();
    return new Promise<ShopAccessory[]>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/accessories/shopItems';
      axios.get(requestURL, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject)).catch((err)=>handleError(err, reject));
    });
  }

  /*
    returns Array of Accessories that the user owns
  */
  getUserAccessories(requestedCollections?: string[]): Promise<Accessory[]> {
    const axiosAuthConfig = checkAuth();
    return new Promise<Accessory[]>((resolve, reject) => {
    
      const requestURL:string = HOST + API_VERSION + '/accessories';
      axios.post(requestURL,  { requestedCollections }, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject)).catch((err)=>handleError(err, reject));
      });
  }

  getAccessory(accessoryId:string): Promise<Accessory> {
    const axiosAuthConfig = checkAuth();
    return new Promise<Accessory>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/accessories/' + accessoryId;
      axios.get(requestURL, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject)).catch((err)=>handleError(err, reject));
      });
  }

  // relies on accessories updatedAt index (-1) 
  getLastAccessoriesUpdate(): Promise<Date> {
    const axiosAuthConfig = checkAuth();
    return new Promise<Date>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/accessories/lastUpdatedAccessory';
        axios.get(requestURL, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject)).catch((err)=>handleError(err, reject));
      });
  }
  
  getListings(): Promise<MarketInvoice[]> {
    const axiosAuthConfig = checkAuth();
    return new Promise<MarketInvoice[]>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/accessories/marketListings';
      axios.get(requestURL, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject)).catch((err)=>handleError(err, reject));
    });
  }

  getInvoiceData(status:string): Promise<MarketInvoice[]> {
    const axiosAuthConfig = checkAuth();
    return new Promise<MarketInvoice[]>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/accessories/history/' + status;
      axios.get(requestURL, axiosAuthConfig).then((res)=>handleResponse(res, resolve, reject)).catch((err)=>handleError(err, reject));
    });
  }

  getInvoiceDataNew(status:string): Promise<any[]> {
    const axiosAuthConfig = checkAuth();
    return new Promise<any[]>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/accessories/history/' + status;
      axios.get(requestURL, axiosAuthConfig).then((res)=>handleResponse(res, resolve, reject)).catch((err)=>handleError(err, reject));
    });
  }

  getSalesData(): Promise<MarketInvoice[]> {
    const axiosAuthConfig = checkAuth();
    return new Promise<MarketInvoice[]>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/accessories/allSales';
      axios.get(requestURL, axiosAuthConfig).then((res)=>handleResponse(res, resolve, reject)).catch((err)=>handleError(err, reject));
    });
  }

  getUserInvoices(handle:string): Promise<MarketInvoice[]> {
    const axiosAuthConfig = checkAuth();
    return new Promise<MarketInvoice[]>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/accessories/history/user/' + handle;
      axios.get(requestURL, axiosAuthConfig).then((res)=>handleResponse(res, resolve, reject)).catch((err)=>handleError(err, reject));
    });
  }
  
  getInvoicesByAccessory(id:string, status:string): Promise<MarketInvoice[]> {
    const axiosAuthConfig = checkAuth();
    return new Promise<MarketInvoice[]>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/accessories/history/item/' + id + '/' + status;
      axios.get(requestURL, axiosAuthConfig).then((res)=>handleResponse(res, resolve, reject)).catch((err)=>handleError(err, reject));
    });
  }

  getInvoicesByUser(handle:string, status:string): Promise<any[]> {
    const axiosAuthConfig = checkAuth();
    return new Promise<any[]>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/accessories/history/user/' + handle + '/' + status;
      axios.get(requestURL, axiosAuthConfig).then((res)=>handleResponse(res, resolve, reject)).catch((err)=>handleError(err, reject));
    });
  }
/*
  valid currencies are: 'TREAT', 'CRYPTO',
      'ARS', 'AUD', 'BRL', 'CAD', 'CHF', 'CNY', 'COP', 'CZK', 'DKK', 'EUR', 'GBP', 'HKD', 'JPY', 'KRW', 'MXN', 'NOK', 'NZD', 'PHP', 'RUB', 'SEK', 'SGD', 'THB', 'USD', 'ZAR'
  returns {newBalance:number} with newBalance being the new TREAT balance if currency is TREAT, otherwise an array of user owned itemIds {items:string[]}
*/
  buyItem(currency:string, itemId:string):Promise<buyItemResponse>
  {
    const axiosAuthConfig = checkAuth();
      return new Promise<buyItemResponse>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/accessories/buyItem';
      const params = {currency: currency, itemId: itemId};
      axios.post(requestURL, params, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject )).catch((err)=>handleError(err, reject));
      }); 
  }

  sendAllBack()
  {
    const axiosAuthConfig = checkAuth();
    return new Promise<buyItemResponse>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/accessories/sendAllBack';
      const params = {};
      axios.post(requestURL, params, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject )).catch((err)=>handleError(err, reject));
      }); 
  }

  listMarketItem(itemId: string, number: number, price: number, currency: string):Promise<boolean> {
    const axiosAuthConfig = checkAuth();
    return new Promise<boolean>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/accessories/listMarketItem';
      const params = { itemId, number, price, currency };
      axios.post(requestURL, params, axiosAuthConfig).then((res)=>handleResponse(res, resolve, reject)).catch((err)=>handleError(err, reject));
    }); 
  }

  cancelMarketItem(invoiceId: string):Promise<boolean> {
    const axiosAuthConfig = checkAuth();
    return new Promise<boolean>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/accessories/cancelMarketItem';
      const params = { invoiceId };
      axios.post(requestURL, params, axiosAuthConfig).then((res)=>handleResponse(res, resolve, reject)).catch((err)=>handleError(err, reject));
    }); 
  }

  buyMarketItem(invoiceId: string, itemId: string, number: number, price: number, currency: string):Promise<boolean> {
    const axiosAuthConfig = checkAuth();
    return new Promise<boolean>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/accessories/buyMarketItem';
      const params = { invoiceId, itemId, number, price, currency };
      axios.post(requestURL, params, axiosAuthConfig).then((res)=>handleResponse(res, resolve, reject)).catch((err)=>handleError(err, reject));
    }); 
  }

}

export const marketApi = new MarketApi();
