import { useRef, useState, useEffect } from 'react';
import type { ElementType, FC } from 'react';
import { subHours } from 'date-fns';
import {
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Tooltip,
  Typography
} from '@mui/material';
import BellIcon from '../../icons/Bell';
import ChatAltIcon from '../../icons/ChatAlt';
import CreditCardIcon from '../../icons/CreditCard';
import ShoppingCartIcon from '../../icons/ShoppingCart';
import { useNavigate } from 'react-router';

interface Notification {
  id: string;
  title: string;
  description: string;
  type: string;
  createdAt: number;
  url?: string;
}

const now = new Date();


const allNotifications: Notification[] = [
  {
    id: '5e8883f1b51cc1956a5a1ec0',
    createdAt: subHours(now, 2).getTime(),
    description: '@durodogs',
    title: 'You can follow us on Twitter to receive exclusive tips and updates about Duro Dogs.',
    type: 'new_message',
    url: 'https://twitter.com/DuroDogs'
  },
  {
    id: '5e8883f1b51cc1956a5a1ec1',
    createdAt: subHours(now, 2).getTime(),
    description: 'Earn treats and rare items.',
    title: 'Click here to play Ruff Runner, the first extension game in Duro Dogs Park.',
    type: 'new_message',
    url: 'https://www.ruffrunner.app'
  },
  {
    id: '5e8883f1b51cc1956a5a1ec5',
    createdAt: subHours(now, 2).getTime(),
    description: 'Share the game, share the reward!',
    title: 'Want to earn extra treats and cash for your pup? Make sure to check out referrals in the side bar!',
    type: 'new_message',
    url: '/dashboard/ReferralProgram',
  },
];


const iconsMap: Record<string, ElementType> = {
  item_shipped: ShoppingCartIcon,
  new_message: ChatAltIcon,
  order_placed: CreditCardIcon
};



const NotificationsPopover: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState<boolean>(false); 
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [read, setRead] = useState(0);

  const navigate = useNavigate();

  useEffect(()=>{
    try {
      const savedReadIds = window.localStorage.getItem('notificationRead');
      if(savedReadIds) {
        const readArr = JSON.parse(savedReadIds);
        setNotifications(allNotifications.filter((n) => !readArr.includes(n.id)));
      }
      else {
        setNotifications(allNotifications);
      }
    }
  catch(e) {
    console.log(e);
  }
  }, [read]);

  function markAllRead() {
    try {
      const readArr = []
      allNotifications.forEach((n) => {
        readArr.push(n.id);
      });
      window.localStorage.setItem('notificationRead', JSON.stringify(readArr));
      setRead((old)=>old+1);
      setOpen(false);
    }
    catch(e) {
      console.log(e);
    }
  }

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  async function handleNotificationClick(notification: Notification) {
    if(notification.url) {
      if(notification.url.startsWith('https')) {
        window.open(notification.url);
      } else {
        navigate(notification.url);
      }
    }
  }

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton
          color="inherit"
          ref={anchorRef}
          onClick={handleOpen}
        >
          <Badge
            color="error"
            badgeContent={notifications.length}
          >
            <BellIcon fontSize="small" />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 320 }
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="h6"
          >
            Notifications
          </Typography>
        </Box>
        {
          notifications.length === 0
            ? (
              <Box sx={{ p: 2 }}>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  There are no notifications
                </Typography>
              </Box>
            )
            : (
              <>
                <List disablePadding>
                  {notifications.map((notification) => {
                    const Icon = iconsMap[notification.type];

                    return (
                      <ListItem
                        divider
                        key={notification.id}
                        onClick={()=>{handleNotificationClick(notification);}}
                      >
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              backgroundColor: 'primary.main',
                              color: 'primary.contrastText'
                            }}
                          >
                            <Icon fontSize="small" />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={(
                            <Link
                              color="textPrimary"
                              sx={{ cursor: 'pointer' }}
                              underline="none"
                              variant="subtitle2"
                            >
                              {notification.title}
                            </Link>
                          )}
                          secondary={notification.description}
                        />
                      </ListItem>
                    );
                  })}
                </List>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    p: 1
                  }}
                >
                  <Button
                    color="primary"
                    size="small"
                    variant="text"
                    onClick={markAllRead}
                  >
                    Mark all as read
                  </Button>
                </Box>
              </>
            )
        }
      </Popover>
    </>
  );
};

export default NotificationsPopover;
