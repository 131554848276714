import { useState, createContext, useReducer } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import MainNavbar from './MainNavbar';
import MainSidebar from './MainSidebar';
import {
  Box
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../theme/globalTheme'
import ErrorBoundary from './ErrorBoundary';

// const theme = createTheme({
//   palette:{
//     primary:{
//       main:'#0F7AE8',
//       dark:'#273860'
//     }
//   }
// })

interface MainLayoutProps {
  children?: ReactNode;
}

export const OnClickContext = createContext(null);


const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState<boolean>(false);



  
  return (
    <ThemeProvider theme={theme}>
        <Box bgcolor="primary.main">
          {/* <MainNavbar
            onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)}
          /> */}
          {/* <MainSidebar
            onMobileClose={(): void => setIsSidebarMobileOpen(false)}
            openMobile={isSidebarMobileOpen}
          /> */}
            {children || <Outlet />}
          <Footer />
        </Box>

      
    </ThemeProvider>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node
};

export default MainLayout;
