import GoogleLogin from 'react-google-login';
import { gapi } from 'gapi-script';
import { facebookAppId, googleClientId } from 'src/__api__/apiConfig';
import { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Grid, Icon, IconButton, Stack, Typography } from '@mui/material';
import { cleanupLS, redirectToNFTYLogin } from 'src/contexts/JWTContext';
import FacebookLogin from '@greatsumini/react-facebook-login';
import PasswordInput from './PasswordInput';
import { customerApi } from 'src/__api__/customerApi';
import PlaygroundMenuError from '../dashboard/play/PlaygroundMenuError';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';


const HandcashError = ({closeHandler})=> {

    const HandcashButton = ({buttonText, onClickHandler = null, lastAction= null, logout = false, fullWidth=false })=>{
        
       return(
       <Button onClick={onClickHandler} startIcon={<img style={{ height: '1.5em', marginBottom: '2px', width: '1.5em' }} src='/static/icons/handcash1024.png' />} sx={{
           height: '4em',
           backgroundColor: '#38CB7B', marginLeft: 'auto', marginRight: 'auto',
           color: 'white',
           fontSize: '1em',
           px: '1em',
           width: fullWidth?'100%':undefined,
           textTransform: 'none',
           '&:hover': {
               backgroundColor: '#38CB7B',
               transform: 'scale(1.01)',
           }
       }}> {buttonText}</Button>
       );
   }

    async function loginWithHandcash() {
        redirectToNFTYLogin();
    }
    return (
        <>
        <Stack gap={2} justifyContent='center' sx={{ position: 'relative', borderRadius: '1em', fontSize: {xs: '3.3vw', md: '1.5rem'}, width: '120%', marginLeft: '-10%', backgroundColor: '#231F20', color: 'white', padding: '2em', }}>
        <IconButton aria-label="close" sx={{position: 'absolute', zIndex: 1, left: 0, top: 0}} onClick={closeHandler}>
        <ClearRoundedIcon sx={{ color: 'white', height: '1em', width: '1em' }} />
        </IconButton>
            <Typography sx={{textAlign: 'center', fontSize: '0.9em'}}>Please sign-in with your Handcash to have access to all of Duro Dogs features.
            </Typography>
            <HandcashButton fullWidth buttonText='Login with Handcash' onClickHandler={loginWithHandcash} />

        </Stack>
        </>

    );
}

const LoginContent= ()=> {

    const [password, setPassword] = useState(null);
    const [email, setEmail] = useState(null);
    const [passwordReseted, setPasswordReseted] = useState(false);
    const [passwordResetClicked, setPasswordResetClicked] = useState(false);
    const [loginSubmitted, setLoginSubmitted] = useState(false);
    const [error, setError] = useState(null);
    const [cookiesEnabled, setCookiesEnabled] = useState(true);
    const [valid, setValid] = useState(false);
    const [handcashError, setHandcashError] = useState(false);
    

    const responseGoogle = (response) => {
        if (response.error) {
            if (response.error === 'idpiframe_initialization_failed') {
                setCookiesEnabled(false);
                setError('Please make sure you have third party cookies enabled to use Google Login (incognito mode, plugin, browsersettings might disable this)');
            } else {
                setError(response.details?response.details:response.error);
            }
        } else {
            cleanupLS();
            window.localStorage.setItem('accessToken', response.getAuthResponse().id_token);// gapi.auth.getToken().id_token);
            window.location.href= window.location.href.includes('localhost')?'http://localhost:3000':'https://www.durodogs.com';
        }
    }

    const responseFacebook = async (response) => {
        if (response.error || !response?.accessToken) {
            setError(response.status);
        } else {
            cleanupLS();
            // const final = await fetch('https://graph.facebook.com/me?access_token=' + response.accessToken); 
            window.localStorage.setItem('accessToken', response.accessToken);// gapi.auth.getToken().id_token);
            window.location.href= window.location.href.includes('localhost')?'http://localhost:3000':'https://www.durodogs.com';
        }
    }

    const errorFacebook = async (error) => {
        setError(error.message);
    }

    function componentClicked() {

    }

    function onValidInput(password: string, email: string) {
        setPassword(password);
        setEmail(email);
        if (password && email) {
            setValid(true);
        } else {
            setValid(false);
        }
    }

    async function resetClicked() {
        try {
            setPasswordResetClicked(true);
        } catch(e) {
            setError(e.message);
        }
    }

    async function requestReset() {
        try {
            if (email) {
                setLoginSubmitted(true);
                customerApi.resetPasswordClicked(email).then(()=>{
                    setPasswordReseted(true);
                }).catch((e) => {
                    setError(e.message);
                    setLoginSubmitted(false);
                });
            }
        } catch(e) {
            setError(e.message);
            setLoginSubmitted(false);
        }
    }

    async function loginClicked() {
        if(password && email) {
            try {
                setLoginSubmitted(true);
                cleanupLS();
                customerApi.loginEmail(email, password).then((token) => {
                    window.localStorage.setItem('accessToken', token.accessToken);
                    window.location.href= window.location.href.includes('localhost')?'http://localhost:3000':'https://www.durodogs.com';
                }).catch((e) => {
                    console.log(e.message);
                    if (e.message === 'you already a handcash account, log in with this instead') {
                        setHandcashError(true);
                    } else {
                        setError(e.message);
                    }
                    setLoginSubmitted(false);
                });
            } catch(e) {
                  
                     setError(e.message);
                    setLoginSubmitted(false);
                }
            
        }
    }


    useEffect(()=>{
        try {
        function start () {
            gapi.client.init( {
                clientId: googleClientId,
                scope: '',
            })
        };
        gapi.load('client: auth2', start);
    } catch(e) {
        setError('initializing google login failed');
        console.log(e.message);
    }
    }, []);

    useEffect(()=>{
        if (window.localStorage.getItem('alreadyHandcash')) {
           window.localStorage.removeItem('alreadyHandcash');
            setHandcashError(true);
        }
    },[])

    return (
        <>
        {passwordReseted?<>
            <Typography sx={{fontWeight: 'bold', fontSize: {xs: '4vw', md: '1.25rem'}, textAlign: 'center', my: '1em', color: 'white'}}>Reset my Duro Dogs Password</Typography>
            <Typography sx={{textAlign: 'center',fontSize: {xs: '4vw', md: '1.25rem'}, my: '1em', color: 'white'}}>A verification link has been sent to your email address. Please check your spam folder if you do not see it.  </Typography>
            <Typography sx={{fontWeight: 'bold', fontSize: {xs: '4vw', md: '1.25rem'}, textAlign: 'center', my: '1em', color: 'white'}}>Submitted Email Address: </Typography>
            <Typography sx={{textAlign: 'center',fontSize: {xs: '4vw', md: '1.25rem'}, my: '1em', color: 'white'}}>{email}</Typography>
        </>:
        <>
        
        <Stack gap={{xs: 1, md: 1}} justifyContent={{xs: "space-between", md: 'flex-start'}} sx={{position: 'relative', width: {xs: '100%', md: '100%'}}}>
        {handcashError && 
                <Box sx={{position: 'absolute', zIndex: 1, top: '0%', padding: {xs: '0rem', md: '1rem'}}}>
                    <HandcashError closeHandler={()=>{setHandcashError(false);}} />
                </Box>
                }
                {!passwordResetClicked && <Typography sx={{color: 'white', width: '100%', fontSize: {xs: '4vw', md: '1.5rem'} , textAlign: 'center', my: '1em'}}>Please sign in to see your pups.</Typography>}
                        {!passwordResetClicked && <>
        <Button onClick={() => {cleanupLS(); redirectToNFTYLogin(); } } startIcon={<img style={{ height: '1.5rem', marginBottom: '2px', width: '1.5rem' }} src='/static/icons/handcash1024.png' />} sx={{
            height: {xs: '11vw', md: '3em'}, width: '100%', maxWidth: '469px',
            backgroundColor: '#38CB7B', marginLeft: 'auto', marginRight: 'auto',
            color: 'white',
            borderRadius: '0.45em',
            fontSize: { xs: '3.1vw', sm: '1.1rem', md: '1.25rem' },
            textTransform: 'none',
            '&:hover': {
                backgroundColor: '#38CB7B',
                transform: 'scale(1.01)',
            }
        }}>Login with Handcash</Button>
        <Typography sx={{color: 'white', fontSize: {xs: '6vw', md: '2rem'}, width: '100%', my: {xs: '2vw', md: '1rem'}, textAlign: 'center'}}>or</Typography>
            <SocialLoginButtons   googleClientId={googleClientId} responseGoogle={cookiesEnabled?responseGoogle:null} componentClicked={componentClicked} responseFacebook={responseFacebook}  />

        </>}
        {passwordResetClicked && 
        <Box sx={{width: '80%', mx: '10%'}}>
        <Typography sx={{fontWeight: 'bold', fontSize: {xs: '4vw', md: '1.25rem'}, textAlign: 'center', my: '1em', color: 'white'}}>Reset my Duro Dogs Password</Typography>
        <Typography sx={{textAlign: 'center',fontSize: {xs: '4vw', md: '1.25rem'}, my: '1em', color: 'white'}}>Please enter the Email Address associated with your Duro Dogs Account: </Typography>
        </Box>
        }
        <Box sx={{my: '1em'}}><PasswordInput onValidInput={onValidInput} showEmail={true} showPassword={!passwordResetClicked} showError={false} showHelp={false} showConfirm={false} /></Box>
        {!passwordResetClicked && <>
        {loginSubmitted?<CircularProgress />:<Button variant="contained" sx={{py: '1em', backgroundColor: 'white', color: '#1E74DD', '&:hover': { backgroundColor: '#eee', color: '#1E74DD'}}} onClick={valid?loginClicked:()=>{}}>Login</Button>}
        <Button sx={{color: 'white', textDecoration: 'underline', py: '1em', textTransform: 'none'}} onClick={resetClicked}>Forgot Password?</Button>
        </>}
        {passwordResetClicked && 
            <>{loginSubmitted?<CircularProgress />:
            <Box>
                <Button variant="contained" sx={{textTransform: 'none', color: '#1E74DD', py: '1em', width: '100%', backgroundColor: 'white', borderRadius: '0.5em'}} onClick={requestReset}>Send Reset Email</Button>
            </Box>
            
            }</>}
            <PlaygroundMenuError error={error} onClose={(event, reason)=>{setError(null);}} open={error!==null}/> 
    
        </Stack>
       
        </>}

        </>
    );

    function SocialLoginButtons({googleClientId, responseGoogle, componentClicked, responseFacebook}) {

        const FacebookButton = (renderProps) => (
            <Button sx={{backgroundColor: 'white', color: '#0668E1', py: '0.7em', width: '100%', textTransform: 'none',  borderRadius: '0.45em', fontSize: {xs: '2.5vw', md: '1rem'}, paddingLeft: {xs: '1em', md: '1em'}, paddingRight: {xs: '3em', md: '4.2em'}, textAlign: 'center', '&:hover': { backgroundColor: '#eee', color: '#0668E1'}}} 
            onClick={renderProps.onClick} startIcon={<Icon sx={{height: '1.3em',  width: '1.3em',paddingRight: {xs: '1.7em', md: '2.9em'}}}><img style={{height: '1.3em'}} src='/static/icons/facebook.png' alt=''/></Icon>}>Facebook</Button>
        )

        const  GoogleButton = (renderProps) => (
            <Button sx={{backgroundColor: 'white', color: '#323232', py: '0.7em', textTransform: 'none', width: '100%', borderRadius: '0.45em', textAlign: 'center', paddingLeft: {xs: '1em',md: '1em'}, paddingRight: {xs: '3em',md: '4.2em'}, fontSize: {xs: '2.5vw', md: '1rem'}, '&:hover': { backgroundColor: '#eee', color: '#323232'}}} 
            onClick={renderProps.onClick} startIcon={<Icon sx={{height: '1.3em', width: '1.3em', paddingRight: {xs: '1.7em', md: '2.9em'}}}><img style={{height: '1.3em'}} src='/static/icons/google.png' alt=''/></Icon>}>Google</Button>
        )

      return (<Grid container justifyContent='stretch' alignItems='center' direction='row' gap={{xs: 2, md: 3}} sx={{width: {xs: '100%', md: '100%'}, my: '0rem'}}>
            <Grid item xs={true}>{responseGoogle && <GoogleLogin clientId={googleClientId} render={GoogleButton} buttonText="Google" onSuccess={responseGoogle} onFailure={responseGoogle} cookiePolicy={'single_host_origin'} />}</Grid>

            <Grid item xs={true}><FacebookLogin appId={facebookAppId} autoLoad={false} render={({onClick, logout}) => <FacebookButton onClick={onClick} onLogoutClick={logout} />} fields="email,picture" onFail={errorFacebook} onSuccess={responseFacebook} /></Grid>
            </Grid>);
    }
  }

export default LoginContent;