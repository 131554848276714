import { API_VERSION, HOST, handleError, handleResponse, checkAuth } from './apiConfig';
import type { Customer, NotificationType } from '../types/customer';
import { User } from '@segment/analytics-next';
import axios from 'axios';;



class CustomerApi {

  /*
    if authToken is a newCustomer token, a new user is created and returned, otherwise it returns the currently logged in user
  */
  registration():Promise<Customer>{
    const axiosAuthConfig = checkAuth();
    return new Promise<Customer>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/customers/registration';
      axios.post(requestURL, {}, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject)).catch((err)=>handleError(err, reject));
      });
  }
  /*
    ATM only 'USD' is implemented on API side
    valid currencies: 'CRYPTO',
      'ARS', 'AUD', 'BRL', 'CAD', 'CHF', 'CNY', 'COP', 'CZK', 'DKK', 'EUR', 'GBP', 'HKD', 'JPY', 'KRW', 'MXN', 'NOK', 'NZD', 'PHP', 'RUB', 'SEK', 'SGD', 'THB', 'USD', 'ZAR'
  */
  buyTreat(amount:number, currency:string):Promise<{newBalance: number}>{
    const axiosAuthConfig = checkAuth();
    return new Promise<{newBalance: number}>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/customers/buyTreat';
      const params = {amount: amount, currency: currency};
      axios.post(requestURL, params, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject )).catch((err)=>handleError(err, reject));
      });
  }

  redirectionUrl():Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/customers/redirectionUrl';
      axios.get(requestURL).then((res)=>handleResponse(res,resolve, reject)).catch((err)=>handleError(err, reject));
    });
  }

  getToken(handcashToken: string):Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/customers/getToken';
      const params = { handcashToken };
      axios.post(requestURL, params).then((res)=>handleResponse(res,resolve, reject)).catch((err)=>handleError(err, reject));
    });
  }

  getNotifications():Promise<NotificationType[]> {
    const axiosAuthConfig = checkAuth();
    return new Promise<NotificationType[]>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/customers/notifications';
      axios.get(requestURL, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject)).catch((err)=>handleError(err, reject));
      });
  }

  checkHandle(handle: string):Promise<string | undefined>{
    const axiosAuthConfig = checkAuth();
    return new Promise<string | undefined>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/customers/checkHandle';
      const params = { handle };
      axios.post(requestURL, params, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject)).catch((err)=>handleError(err, reject));
      });
  }

  notificationRead(notificationId: string):Promise<{success: boolean}>{
    const axiosAuthConfig = checkAuth();
    return new Promise<{success: boolean}>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/customers/notificationRead';
      const params = { notificationId };
      axios.post(requestURL, params, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject )).catch((err)=>handleError(err, reject));
      });
  }

  allNotificationRead():Promise<{success: boolean}>{
    const axiosAuthConfig = checkAuth();
    return new Promise<{success: boolean}>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/customers/allNotificationsRead';
      const params = {};
      axios.post(requestURL, params, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject )).catch((err)=>handleError(err, reject));
      });
  }

  setTutorialState(state: number):Promise<User> {
    const axiosAuthConfig = checkAuth();
    return new Promise<User>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/customers/setTutorialState';
      const params = { tutorialState: state };
      axios.post(requestURL, params, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject )).catch((err)=>handleError(err, reject));
      });
  }

  resetPassword(resetId: string, newPassword: string, email: string):Promise<{accessToken: string}> {
    return new Promise<{accessToken: string}>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/customers/resetPassword';
      const params = { email, password: newPassword, resetUUID: resetId };
      axios.post(requestURL, params, {}).then((res)=>handleResponse(res,resolve, reject )).catch((err)=>handleError(err, reject));
      });
  }

  resetPasswordClicked(email: string):Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/customers/resetPasswordClicked';
      const params = { email };
      axios.post(requestURL, params, {}).then((res)=>handleResponse(res,resolve, reject )).catch((err)=>handleError(err, reject));
      });
  }

  loginEmail(email: string, password: string):Promise<{accessToken: string}> {
    return new Promise<{accessToken: string}>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/customers/loginEmail';
      const params = { email, password };
      axios.post(requestURL, params, {}).then((res)=>handleResponse(res,resolve, reject )).catch((err)=>handleError(err, reject));
      });
  }

  registerEmail(email: string, password: string, reservedDogId: string, referredBy?: string, anonId?: string):Promise<{accessToken: string}> {
    return new Promise<{accessToken: string}>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/customers/registerEmail';
      const params = { email, password, reservedDogId, referredBy, anonId };
      axios.post(requestURL, params, {}).then((res)=>handleResponse(res,resolve, reject )).catch((err)=>handleError(err, reject));
      });
  }

  confirmEmail(handle: string, uuidLink: string, tempHandle: string):Promise<{accessToken: string}> {
    return new Promise<{accessToken: string}>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/customers/confirmEmail';
      const params = { handle, uuidLink, tempHandle };
      axios.post(requestURL, params, {}).then((res)=>handleResponse(res,resolve, reject )).catch((err)=>handleError(err, reject));
      });
  }

  changeEmailHandle(newEmail: string, anonId: string):Promise<{accessToken: string}> {
    const axiosAuthConfig = checkAuth();
    return new Promise<{accessToken: string}>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/customers/changeEmailHandle';
      const params = { newEmail, anonId };
      axios.post(requestURL, params, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject )).catch((err)=>handleError(err, reject));
      });
  }

  registerGoogle(reservedDogId: string, anonId: string, referredBy?: string):Promise<{success: boolean}>{
    const axiosAuthConfig = checkAuth();
    return new Promise<{success: boolean}>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/customers/registerGoogle';
      const params = { reservedDogId, anonId, referredBy };
      axios.post(requestURL, params, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject )).catch((err)=>handleError(err, reject));
      });
  }

  changePassword(password: string):Promise<{success: boolean}>{
    const axiosAuthConfig = checkAuth();
    return new Promise<{success: boolean}>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/customers/changePassword';
      const params = { password };
      axios.post(requestURL, params, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject )).catch((err)=>handleError(err, reject));
      });
  }

  mergeAccounts(handcashAccount: string):Promise<{success: boolean}>{
    const axiosAuthConfig = checkAuth();
    return new Promise<{success: boolean}>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/customers/mergeAccounts';
      const params = { handcashAccount };
      axios.post(requestURL, params, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject )).catch((err)=>handleError(err, reject));
      });
  }
}


export const customerApi = new CustomerApi();
