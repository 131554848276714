import type { Accessory, ShopAccessory, buyItemResponse, MarketInvoice } from '../types/inventory';
import { API_VERSION, HOST, handleError, handleError2, handleResponse, checkAuth } from './apiConfig';
import axios from 'axios';;




class InventoryApi {
  // allItems: unfiltered // liveItems: filted for .live // shopItems: filtered for !.disabled //
  getAccessories(type:string='allItems'): Promise<ShopAccessory[]> {
    const axiosAuthConfig = checkAuth();
    return new Promise<ShopAccessory[]>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/accessories/' + type;
      axios.get(requestURL, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject)).catch((err)=>handleError(err, reject));
    });
  }

  /*
    returns Array of Accessories, which represent all available items top shop
  */
  getShopAccessories(): Promise<ShopAccessory[]> {
    const axiosAuthConfig = checkAuth();
    return new Promise<ShopAccessory[]>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/accessories/shopItems';
      axios.get(requestURL, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject)).catch((err)=>handleError(err, reject));
    });
  }

  /*
    returns Array of Accessories, that are not released yet, checks for items not in the db and adds them, admin only
  */
    getNewItems(): Promise<ShopAccessory[]> {
      const axiosAuthConfig = checkAuth();
      return new Promise<ShopAccessory[]>((resolve, reject) => {
        const requestURL:string = HOST + API_VERSION + '/accessories/newItems';
        axios.get(requestURL, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject)).catch((err)=>handleError(err, reject));
      });
    }

    /*
    returns Array of Accessories, that are currently active dig items, admin only
  */
    getActiveDigSet(): Promise<ShopAccessory[]> {
      const axiosAuthConfig = checkAuth();
      return new Promise<ShopAccessory[]>((resolve, reject) => {
        const requestURL:string = HOST + API_VERSION + '/accessories/getActiveDigSet';
        axios.get(requestURL, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject)).catch((err)=>handleError(err, reject));
      });
    }

    /*
    returns Array of Accessories, which represent all available items top shop
  */
    getOverview(): Promise<ShopAccessory[]> {
      const axiosAuthConfig = checkAuth();
      return new Promise<ShopAccessory[]>((resolve, reject) => {
        const requestURL:string = HOST + API_VERSION + '/accessories/userOverview';
        axios.get(requestURL, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject)).catch((err)=>handleError(err, reject));
      });
    }

  /*
    returns Array of Accessories that the user owns
  */
  getUserAccessories(requestedCollections?: string[]): Promise<Accessory[]> {
    const axiosAuthConfig = checkAuth();
    return new Promise<Accessory[]>((resolve, reject) => {
    
      const requestURL:string = HOST + API_VERSION + '/accessories';
      axios.post(requestURL, { requestedCollections }, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject)).catch((err)=>handleError(err, reject));
      });
  }

  getAccessory(accessoryId:string): Promise<Accessory> {
    const axiosAuthConfig = checkAuth();
    return new Promise<Accessory>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/accessories/' + accessoryId;
      axios.get(requestURL, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject)).catch((err)=>handleError(err, reject));
      });
  }

  saveItem(item: any):Promise<boolean> {
    const axiosAuthConfig = checkAuth();
    return new Promise<boolean>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/accessories/saveNewItem';
      const params = { newItem: item };
      axios.post(requestURL, params, axiosAuthConfig).then((res)=>handleResponse(res, resolve, reject)).catch((err)=>handleError2(err, reject));
    }); 
  }
  
  getListings(): Promise<MarketInvoice[]> {
    const axiosAuthConfig = checkAuth();
    return new Promise<MarketInvoice[]>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/accessories/marketListings';
      axios.get(requestURL, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject)).catch((err)=>handleError(err, reject));
    });
  }
/*
  valid currencies are: 'TREAT', 'CRYPTO',
      'ARS', 'AUD', 'BRL', 'CAD', 'CHF', 'CNY', 'COP', 'CZK', 'DKK', 'EUR', 'GBP', 'HKD', 'JPY', 'KRW', 'MXN', 'NOK', 'NZD', 'PHP', 'RUB', 'SEK', 'SGD', 'THB', 'USD', 'ZAR'
  returns {newBalance:number} with newBalance being the new TREAT balance if currency is TREAT, otherwise an array of user owned itemIds {items:string[]}
*/
  buyItem(currency:string, itemId:string):Promise<buyItemResponse>
  {
    const axiosAuthConfig = checkAuth();
      return new Promise<buyItemResponse>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/accessories/buyItem';
      const params = {currency: currency, itemId: itemId };
      axios.post(requestURL, params, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject )).catch((err)=>handleError(err, reject));
      }); 
  }

  sendAllBack()
  {
    const axiosAuthConfig = checkAuth();
    return new Promise<buyItemResponse>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/accessories/sendAllBack';
      const params = {};
      axios.post(requestURL, params, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject )).catch((err)=>handleError(err, reject));
      }); 
  }

  sendItem(toHandle: string, nftId: string, itemName: string, number: number):Promise<{
    status: string; // should be 'sending'
    serial: number;
    nftId: string;
    toHandle: string;
  }> {
    const axiosAuthConfig = checkAuth();
    return new Promise<{
      status: string; // should be 'sending'
      serial: number;
      nftId: string;
      toHandle: string;
    }>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/accessories/sendItem';
      const params = {toHandle, nftIds: [nftId], itemNames: [itemName], numbers: [number]};
      axios.post(requestURL, params, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject )).catch((err)=>handleError(err, reject));
      }); 
  }

  sendItems(toHandle: string, nftIds: string[], itemNames: string[], numbers: number[]):Promise<{status: boolean, to: string, nftIds: string[] }> {
    const axiosAuthConfig = checkAuth();
    return new Promise<{status: boolean, to: string, nftIds: string[] }>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/accessories/sendItem';
      const params = {toHandle, nftIds, itemNames, numbers};
      axios.post(requestURL, params, axiosAuthConfig).then((res)=>handleResponse(res,resolve, reject )).catch((err)=>handleError(err, reject));
      }); 
  }

  listMarketItem(nftId: string, price: number):Promise<boolean> {
    const axiosAuthConfig = checkAuth();
    return new Promise<boolean>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/accessories/listMarketItem';
      const params = { nftId, price };
      axios.post(requestURL, params, axiosAuthConfig).then((res)=>handleResponse(res, resolve, reject)).catch((err)=>handleError2(err, reject));
    }); 
  }

  editMarketItem(listingId: string, price: number):Promise<boolean> {
    const axiosAuthConfig = checkAuth();
    return new Promise<boolean>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/accessories/editMarketItem';
      const params = { listingId, price };
      axios.post(requestURL, params, axiosAuthConfig).then((res)=>handleResponse(res, resolve, reject)).catch((err)=>handleError2(err, reject));
    }); 
  }

  cancelMarketItem(listingId: string):Promise<boolean> {
    const axiosAuthConfig = checkAuth();
    return new Promise<boolean>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/accessories/cancelMarketItem';
      const params = { listingId };
      axios.post(requestURL, params, axiosAuthConfig).then((res)=>handleResponse(res, resolve, reject)).catch((err)=>handleError2(err, reject));
    }); 
  }

  buyMarketItem(listingId: string):Promise<boolean> {
    const axiosAuthConfig = checkAuth();
    return new Promise<boolean>((resolve, reject) => {
      const requestURL:string = HOST + API_VERSION + '/accessories/buyMarketItem';
      const params = { listingId };
      axios.post(requestURL, params, axiosAuthConfig).then((res)=>handleResponse(res, resolve, reject)).catch((err)=>handleError2(err, reject));
    }); 
  }

}

export const inventoryApi = new InventoryApi();
