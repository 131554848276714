import { createContext, useContext, useEffect, useReducer, useState } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import type { User } from '../types/user';
import { Router, useNavigate } from 'react-router-dom';
import { authApi } from '../__fakeApi__/authApi';
import { Analytics, AnalyticsBrowser } from '@segment/analytics-next'
import useMounted from 'src/hooks/useMounted';
import { getCookie, setCookie, trackEvent } from 'src/utils/analytics';
import { customerApi } from 'src/__api__/customerApi';
import { offline } from 'src/pages/login/SignInPage';


interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

interface AuthContextValue extends State {
  platform: 'JWT';
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  register: (email: string, name: string, password: string) => Promise<void>;
  updateUser: () => Promise<void>;
  getAnalytics: () => Analytics | undefined;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: User;
  };
};

type UpdateUserAction = {
  type: 'UPDATEUSER';
  payload: {
    user: User;
  }
}

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | UpdateUserAction;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};


export function redirectToNFTYLogin() {
  if (typeof window !== 'undefined') {
      customerApi.redirectionUrl().then((url:any)=>{
      window.location.href = url;
    })

  }
}

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state: State, action: RegisterAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  UPDATEUSER:(state: State, action: UpdateUserAction): State => {
    const { user } = action.payload;
    return {
      ...state, 
      user
    };
  }
};

const reducer = (state: State, action: Action): State => (
  handlers[action.type] ? handlers[action.type](state, action) : state
);

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  updateUser: () => Promise.resolve(),
  getAnalytics: () => undefined,
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [analytics, setAnalytics] = useState<Analytics | undefined>(undefined)
  const [writeKey, setWriteKey] = useState('qSdAUrKOnmqIPuA9mjUT0GsDSBkllp0K')
  const navigate = useNavigate();
  const mounted = useMounted();

  useEffect(() => {
    const loadAnalytics = async () => {
      try {
      let [response] = await AnalyticsBrowser.load({ writeKey });

      if(mounted.current) {
        setAnalytics(response)
      }
      
      } catch(e) {
        console.log(e.message);
      }
      
    }
    loadAnalytics()
  }, [writeKey]);

  useEffect(()=>{
    if(analytics && state.user) {
      try {
          if(window.localStorage.getItem('ajs_user_id') !== '"' + state.user.handle + '"') {
           analytics.identify(state.user.handle).then((anares) => {}).catch((e)=>{ console.log('analytics identify went wrong, ', e.message);});
          }
      } catch(e) {
        console.log('identify failed');
      }
    }
  }, [analytics, state])

  async function checkForHandcashAuth(searchParams:any) {
    let handcashAuth = searchParams.get('authToken');
    if (handcashAuth) {
      const state = searchParams.get('state');
      if (state) {
        if (state !== window.location.host) {
          window.location.href = (state==='localhost:3000'?'http://':'https://') + state + '?' + searchParams.toString();
          return;
        }     
      }
      window.localStorage.setItem('accessToken', await customerApi.getToken(handcashAuth));
      window.location.href= '/';
      return true;
    } else {
      return false;
    }
  }


  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        if (offline) {
          throw new Error('offline');
        }
        let paramsString = window.location.search;
        let searchParams = new URLSearchParams(paramsString);
        
        let referredBy = searchParams.get('referredBy');
        let confirmId = searchParams.get('confirmId');
        let fbReturn = searchParams.get('state');
        if (await checkForHandcashAuth(searchParams)) {
          return;
        }
        if (fbReturn && fbReturn.includes('facebook') && window.location.href.includes('/welcome')) {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
          return;
        }
        let automated = window.location.href.includes('/automated/');
        if (referredBy) {
          setCookie('referredBy', referredBy, 30);
        }
        if (searchParams.get('auth') && window.localStorage.getItem('accessToken')){
          await logout();
        }
        if (window.location.search && (searchParams.get("auth") && !window.localStorage.getItem('accessToken'))) {
          // user not logged in
          
          let auth = searchParams.get("auth");
          if(auth) {
            window.localStorage.setItem('accessToken', auth);
            await trackEvent('BACK_FROM_NFTY_JIGS_LOGIN');
          } else {
            redirectToNFTYLogin();
          }
        }

        const accessToken = window.localStorage.getItem('accessToken');
        let anonId = window.localStorage.getItem('ajs_anonymous_id');
        if(anonId) {
          anonId = anonId.slice(1);
          anonId = anonId.slice(0, anonId.length-1);
        }

        if (accessToken) {
          const referredBy = getCookie('referredBy');
          const reservedDogId = window.localStorage.getItem('reservedDogId');
          const tutorialState = window.localStorage.getItem('tutorialState');
          const user = await authApi.me(accessToken, anonId, referredBy, reservedDogId, tutorialState);
          let isNewUser = user.tutorialState <= 8;
          
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
          if(window.location.href.includes('/handcashSignup')) {
            return;
          } 
          if (isNewUser && user?.hasHandcash !== false && user.dogs && user.dogs.length > 0) {
            if (user.tutorialState === 0) {
              navigate('/welcome?hasDog=' + user?.reservedDogId);
              return;
            } else {
                navigate('/dashboard/play/' + user?.reservedDogId);
                return;
            }
          } else {
            if (user?.emailConfirmed=== false) {
              if (!confirmId){
                navigate('/welcome?toConfirm=true&email=' + user?.handle);
                return;
              }
            } 
            else if (user?.tutorialState === 0 && user.dogs && user.dogs.length > 0 && user?.reservedDogId ) {
              navigate('/welcome?hasDog=' + user?.reservedDogId);
              return;
            } else if (user.dogs && user.dogs.length > 0 && user?.tutorialState < 9) {
                if(user?.reservedDogId) {
                  navigate('/dashboard/play/' + user?.reservedDogId);
                } else {
                  navigate('/dashboard/play/' + user.dogs[0]);
                }
                return;
              
            }
          }
          if (user && (searchParams.get("auth") || window.location.href==='http://localhost:3000' || window.location.href==='http://localhost:3000/' || window.location.href === 'https://www.durodogs.com' || window.location.href === 'https://www.durodogs.com/')) {
            navigate('/dashboard');
          }
        } else {

          //failed
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
          if ((window.location.href.substring(window.location.href.length-5).includes('000') || window.location.href.substring(window.location.href.length-5).includes('.com')) && window.localStorage.getItem('actionsData') && !window.location.href.includes('/signin')) {
            // user is returning user, redirect to login page directly
            if(!automated) {
              navigate('/signin');
            }
          }
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string): Promise<void> => {
    const accessToken = await authApi.login({ email, password });
    const user = await authApi.me(accessToken);

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  };

  const logout = async (): Promise<void> => {
    localStorage.removeItem('accessToken');
    cleanupLS();
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (
    email: string,
    name: string,
    password: string
  ): Promise<void> => {
    const accessToken = await authApi.register({ email, name, password });
    const user = await authApi.me(accessToken);
 
    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  const getAnalytics = (): Analytics | undefined =>  {
    return analytics;
  }

  const updateUser = async (): Promise<void> => {
    
    const accessToken = localStorage.getItem('accessToken');
    const user = await authApi.me(accessToken);
    dispatch({
      type: 'UPDATEUSER',
      payload: {
        user
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        logout,
        register,
        updateUser,
        getAnalytics,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;

export const cyrb53 = function(str, seed = 0) {
  let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
      ch = str.charCodeAt(i);
      h1 = Math.imul(h1 ^ ch, 2654435761);
      h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 = Math.imul(h1 ^ (h1>>>16), 2246822507) ^ Math.imul(h2 ^ (h2>>>13), 3266489909);
  h2 = Math.imul(h2 ^ (h2>>>16), 2246822507) ^ Math.imul(h1 ^ (h1>>>13), 3266489909);
  return 4294967296 * (2097151 & h2) + (h1>>>0);
};
export function cleanupLS() {
  try {
  localStorage.removeItem('reservedDog');
  localStorage.removeItem('savedDogs');
  localStorage.removeItem('userAccessory');
  localStorage.removeItem('savedNotifications');
  localStorage.removeItem('tutorialState');
  localStorage.removeItem('reservedDogId');
  } catch(e:any) {
    console.log('removing items from local storage failed, ', e.message);
  }
}



