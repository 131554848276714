import React from 'react';
import { makeStyles } from '@mui/styles';

const useCustomStyles = makeStyles({
  btnGreen: {
    background: 'linear-gradient(to bottom, #23b53e, #73e688)',
    color: 'white',
    // width: '17rem',
    // fontSize: '1.3rem',
    display: 'block',
    "&:hover": {
      background: "rgb(59 59 59) !important"
    }
  },
  btnBlue: {
    background: 'linear-gradient(to bottom, #0181ff,  #266db3)',
    borderRadius: "0.5rem",
    color: 'white',
    width: '17rem',
    fontSize: '1.3rem',
    display: 'block'
  },
  btnWhite: {
    backgroundColor: '#fff',
    borderRadius: "0.5rem",
    width: '17rem',
    fontSize: '1.3rem',
    color: '#0F7AE8',
    '&:hover': {
        background: 'white',
        color: '#0F7AE8',
        filter: 'drop-shadow(0px 0px 1rem rgba(255, 255,255, 0.2))',
    },
  },
  outline: {
    border: 'solid white 3px'
  },
  greenHover: {
    cursor: 'pointer',
    transition: 'color 0.3s ease',
    "&:hover": {
      color: "#23b53e",
      textDecoration: 'none'
    }
  },
  greenHoverSvg: {
    cursor: 'pointer',
    '& path': { transition: 'fill 0.3s ease' },
    "&:hover path": {
      fill: "#23b53e"
    }
  },
  whiteHover: {
    cursor: 'pointer',
    transition: 'color 0.3s ease',
    "&:hover": {
      color: "white !important",
      textDecoration: 'none'
    }
  },
  hideScrollbar: {
    '-ms-overflow-style': 'none',
    'scrollbarWidth': 'none',
    '&::-webkit-scrollbar': {
      display: 'none !important',
    }
  }
})

export default useCustomStyles;
