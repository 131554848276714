import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import type { FC, ReactNode } from 'react';
import { Accessory, ShopAccessory } from 'src/types/inventory';
import { NotificationType } from 'src/types/customer';
import { Dog, DogItem } from 'src/types/dog';
import AuthContext from './JWTContext';
import getTexture from 'src/utils/getTexture';
import { dogApi } from 'src/__api__/dogApi';
import useMounted from 'src/hooks/useMounted';
import { customerApi } from 'src/__api__/customerApi';
import { inventoryApi } from 'src/__api__/inventoryApi';
import { LSWrapper } from 'src/utils/utilFunctions';
import { marketApi } from 'src/__api__/marketApi';

export let globalUserAccessories: Accessory[] = [];

interface Data {
  dogs: Dog[];
  userAccessory: Accessory[];
  userOverview: ShopAccessory[];
  accessoryData: ShopAccessory[];
  // shopAccessory: ShopAccessory[];
  notifications: NotificationType[];
  filledCollections: String[];
  allLoaded: boolean;
  refreshOverview: (force?:boolean)=>Promise<ShopAccessory[]>;
  requestCollections: (collectionIds: string[], force?:boolean, updateItemNotifications?: boolean)=>Promise<Accessory[]>;
  fillDogDetails: (dogs: Dog[], propertiesToNotFillOut?: String[], requestCollectionsEquipped?: boolean, debounceTime?: number, signal?: any) =>  Promise<void>;
  // refreshShop: ()=>void;
  refreshDogs: (force?:boolean)=>void;
  refreshAccessoryData: (force?:boolean)=> Promise<ShopAccessory[]>;
  refreshNotifications: (force?:boolean)=>void;
  updateDog: (dog:Dog, removeItemFromOtherDogs?:DogItem, propertiesToUpdate?: string[]) => void;
  updateItem: (item: Accessory, propertiesToUpdate?: string[]) => void
  readNotification: (notificationId: string) => void;
  readAllNotifications: () => void;
  removeItem: (item: Accessory)  => void;
  removeItems: (items: Accessory[])  => void;
  removeDog: (dog: Dog) => void;
  getDog: (nftId: string, retries?: number) => Promise<Dog | null>;
}


interface DataProviderProps {
  children?: ReactNode;
}





const DataContext = createContext<Data>({
  dogs: [],
  userAccessory: [],
  userOverview: [],
  accessoryData: [],
  // shopAccessory: [],
  notifications: [],
  filledCollections: [], // collectionIds of collections that are already available in userAccessory (if in balance)
  allLoaded: false,
  requestCollections: (collectionIds: string[]) => new Promise<Accessory[]>((res:any, rej:any) => {}),
  fillDogDetails: (dogs: Dog[], propertiesToNotFillOut:String[] = [], requestCollectionsEquipped:boolean, debounceTime: number = 0) => undefined,
  refreshOverview: ()=> new Promise<ShopAccessory[]>((res:any, rej: any)=>{}),
  // refreshShop: ()=> {},
  refreshDogs: ()=> {},
  refreshNotifications: ()=> {},
  refreshAccessoryData: () => new Promise<ShopAccessory[]>((res:any, rej: any)=>{}),
  readAllNotifications: () => {},
  updateDog: (dog:Dog, removeItemFromOtherDogs?:DogItem, propertiesToUpdate?: string[]) => {},
  updateItem: (item: Accessory, propertiesToUpdate?: string[]) => {},
  readNotification: (notificationId: string) => {},
  removeItem: (item: Accessory)  => {},
  removeItems: (items: Accessory[])  => {},
  removeDog: (dog: Dog) => {},
  getDog: (nftId: string, retries?: number) => new Promise<Dog | null>((res:any, rej: any)=>{}),

});



export const DataProvider: FC<DataProviderProps> = (props) => {
  const { children } = props;
  const [dogs, setDogs] = useState<Dog[]>([]);    
  const [userAccessory, setUserAccessory]  = useState<Accessory[]>(globalUserAccessories);
  const [userOverview, setUserOverview] = useState<ShopAccessory[]>([]);
  // const [shopAccessory, setShopAccessory] = useState<ShopAccessory[]>([]);
  const [notifications, setNotifications] = useState<NotificationType[]>([]);
  const [filledCollections, setFilledCollections] = useState<String[]>([]);
  const [dogsLoaded, setDogsLoaded] = useState(false);
  const [notificationsLoaded, setNotificationsLoaded] = useState(false);
  const [itemsLoaded, setItemsLoaded] = useState(false);
  const [initDone, setInitDone] = useState(false);
  const [allLoaded, setAllLoaded] = useState(false);
  const [accessoryData, setAccessoryData] = useState<ShopAccessory[]>([]);

  const prevDogs = useRef(dogs);
  let timeout = null;
  
  

  const auth = useContext(AuthContext);
  const mounted = useMounted();

  function setUserAccessoryHandler(items:Accessory[]) {
    setUserAccessory(items);
    globalUserAccessories = items;
  }

  async function updateDog(dog: Dog, removeItemFromOtherDogs?: DogItem, propertiesToUpdate?: string[]) {
    const newDogs = [...dogs];
    let dogFound = false;
    for(let i = 0; i < newDogs.length; i++) {
      if(newDogs[i].nftId === dog.nftId) {
        dogFound = true;
        if (propertiesToUpdate && propertiesToUpdate.length > 0) {
          propertiesToUpdate.forEach(prop=>{
            newDogs[i][prop] = dog[prop];
          })
        } else {
          newDogs[i] = {...dog, parts: newDogs[i].parts};
        }
        
      }
      else if(removeItemFromOtherDogs) {
        let removeIndex = 0;
        if(newDogs[i]?.items?.find((ditem, index) => {
          if (ditem._id === removeItemFromOtherDogs._id && ditem.serial === removeItemFromOtherDogs.serial) {
            removeIndex = index;
            return true;
          } else {
            return false;
          }
          }
          )) {
          newDogs[i].items.splice(removeIndex, 1);
        }
      }
    }
    if(!dogFound) {
      //dog not existent yet, adding it and making sure parts are correct
      const newDog = {...dog, parts: await getTexture(parseInt(dog.number), dog._id)}
      newDogs.push(newDog);
    }
    setDogs(newDogs);
    try {
      window.localStorage.setItem('savedDogs', JSON.stringify(newDogs));
  } catch(storeError) {
    console.log('writing dogs and items to localstorage failed');
  }
  }


  async function getDog(nftId: string, retries?) {
    const dog = dogs?.find((dog: any) => dog.nftId === nftId)
    if (dog) {
      return dog;
    }
    async function fetchDog(run: number) {
      try {
        const dog = await dogApi.getDogByNftId(nftId);
        if (dog) {
          dogs.push(dog);
          window.localStorage.removeItem('savedDogs');
          return dog;
        }
        if(run < retries && run < 5) {
          return await fetchDog(run+1);
        }
      } catch(e:any) {
        if(run < retries && run < 5) {
          return await fetchDog(run+1);
        }
      }
    }

    return await fetchDog(1);
    
  }

  function removeItem(item: Accessory) {
    const newDogs = [...dogs];
    const newItems = [...userAccessory];
    dogs?.forEach((dog) => {
      let removeIdx = -1;
      dog.items?.forEach((dogItem,index) =>{
        if(dogItem._id === item._id && dogItem.serial === item.serial) {
          removeIdx = index;
        }
      });
      if(removeIdx >= 0) {
        dog.items?.splice(removeIdx, 1);
      }
    });

    const idx = newItems.indexOf(item);
    newItems.splice(idx, 1);
    setUserAccessoryHandler(newItems);
    setDogs(newDogs);
    const newOverview = [...userOverview];
    const collectionThatChanged = newOverview.find((overviewItem)=> overviewItem.collectionId === item.collectionId);
    if (collectionThatChanged) {
      collectionThatChanged.instances -= 1;
    }
    setUserOverview(newOverview);
    saveDataToLS(newItems, newDogs, false);
  }

  function removeItems(items: Accessory[]) {
    const newDogs = [...dogs];
    const newItems = [...userAccessory ];
    const newOverview = [...userOverview];
    items.forEach((item:Accessory)=>{
      newDogs?.forEach((dog) => {
        let removeIdx = -1;
        dog.items?.forEach((dogItem,index) =>{
          if(dogItem._id === item._id && dogItem.serial === item.serial) {
            removeIdx = index;
          }
        });
        if(removeIdx >= 0) {
          dog.items?.splice(removeIdx, 1);
        }
      });
  
      const idx = newItems.indexOf(item);
      newItems.splice(idx, 1);
      const collectionThatChanged = newOverview.find((overviewItem)=> overviewItem.collectionId === item.collectionId);
      if (collectionThatChanged) {
        collectionThatChanged.instances -= 1;
      }
    });
    setUserAccessoryHandler(newItems);
    setDogs(newDogs);
    setUserOverview(newOverview);
    saveDataToLS(newItems, newDogs, false);
  }

  function removeDog(dog: Dog) {
    const newItems = [...userAccessory];
    const newDogs = [...dogs];
    newItems.forEach((item)=>{
      if(item.dog === dog._id) {
        item.dog = null;
      }
    });
    const idx = newDogs.indexOf(dog);
    newDogs.splice(idx, 1);
    setDogs(newDogs);
    setUserAccessoryHandler(newItems);
    saveDataToLS(newItems, newDogs, false);
  }

  function updateItem(item: Accessory, propertiesToUpdate?: string[]) {
    const newUserAccessory = [...userAccessory];
    for(let i = 0; i < newUserAccessory.length; i++) {
      if(newUserAccessory[i].collectionId === item.collectionId && newUserAccessory[i].serial === item.serial)  {
        if (propertiesToUpdate && propertiesToUpdate.length > 0) {
          propertiesToUpdate.forEach(prop=>{
            newUserAccessory[i][prop] = item[prop];
          })
        } else {
          newUserAccessory[i] = item;
        }
        
      }
    }
    setUserAccessoryHandler(newUserAccessory);
    try {
      window.localStorage.setItem('userAccessory', JSON.stringify(newUserAccessory));
    } catch(e) {
      console.log('writing items to localstorage failed');
    }
  }

  async function readNotification(notificationId: string) {
    const readNote = notifications.find((n) => n._id === notificationId);
    if(readNote) {
      readNote.read = true;
    }
    try {
      window.localStorage.setItem('savedNotifications', JSON.stringify(notifications));
  } catch(storeError) {
    console.log('gift notifications to localstorage failed');
  }

  }

  
  async function readAllNotifications() {
    setNotificationsLoaded(false);
    setNotifications([]);
    setNotificationsLoaded(true);
    try {
      window.localStorage.setItem('savedNotifications', JSON.stringify([]));
  } catch(storeError) {
    console.log('writing gift notifications to localstorage failed');
  }

  }

  function isValidCollectionId(collectionId: string) {
    return collectionId && collectionId !== '' && collectionId !== 'undefined' && collectionId !== 'null' && collectionId.length > 10;
  }

  async function requestCollections(requestedCollections: string[], force: boolean = false, updateItemNotifications: boolean = false) : Promise<Accessory[]> {
    // console.log('rquiesting: ', requestedCollections);
    let validCollectionIds = requestedCollections.filter((collectionId: string) => isValidCollectionId(collectionId)); 
    let filtered = validCollectionIds.filter((collectionId:string) => !filledCollections.includes(collectionId));
    // console.log('filtered', filtered);
    // console.log('useracc: ', userAccessory);
    if(filtered.length === 0 && !force) {
      return userAccessory;
    }
    try {
      const payload = force?validCollectionIds:filtered
      const res = (payload && payload.length > 0)?(await inventoryApi.getUserAccessories(payload)):[];
      
      
      const nonReplacedItems = userAccessory.filter((item:Accessory)=> !res.find((col:any)=> col.collectionId === item.collectionId && col.serial === item.serial));
      setUserAccessoryHandler([...res, ...nonReplacedItems]);
      setFilledCollections([...filledCollections, ...filtered]);
      if (updateItemNotifications) {
        initItemsAndDogs(globalUserAccessories, dogs);
      }
    } catch(e:any) {
      console.log(e.message);
    }
    return globalUserAccessories;
  }


  async function loadInventory() {
    if (userOverview && userOverview.length > 0) { 
      return;
    }
    try {
          setItemsLoaded(false);
        const data = await inventoryApi.getOverview();
        if(data) {
            if(mounted) {
                setUserOverview(data);
            }
        }
        setItemsLoaded(true);
      }
      catch(e) {
        console.log("refreshing inventory failed");
      }
  }

  async function refreshOverview():Promise<ShopAccessory[]> {
    try {
          setItemsLoaded(false);
        const data = await inventoryApi.getOverview();
        if(data) {
            if(mounted) {
                setUserOverview(data);
            }
        }
        setItemsLoaded(true);
        return data;
      }
      catch(e) {
        console.log("refreshing inventory failed");
      }
  }

  async function refreshAccessoryData(force:boolean = false) {
    try {
      if (!force && accessoryData && accessoryData.length > 0) {
        return accessoryData;
      }
      const accessoryDataLS = window.localStorage.getItem('accessoryData')
      const lastUpdateAccessoryData = LSWrapper.get('accessoryDatasUpdated');
      const lastDataUpdate = String(await marketApi.getLastAccessoriesUpdate());
      let data = [];
      if (accessoryData && lastUpdateAccessoryData === lastDataUpdate) {
        data = JSON.parse(accessoryDataLS);
      } else {
        data = await marketApi.getAccessoryData();
        try {
          LSWrapper.set('accessoryDatas', data);
          LSWrapper.set('accessoryDatasUpdated', lastDataUpdate);
        } catch(storeError) {
          console.log('writing items to localstorage failed');
        }
      }
      
      if(data && data.length > 0 && mounted.current) {
        setAccessoryData(data);
        return data;
      }
      /* shopAccessories.forEach((item)=> {
        if (item.instances.length < 700 &&  (item.rarity === 'common' || item.rarity === 'digOnly')) {
          console.log(item.name, item.instances.length);
        }
      }); */
    }
    catch(e) {
      console.log("refreshing shop failed", e.message);
    }

  }

  async function fillDogDetails(dogs: Dog[], propertiesToNotFillOut: String[] = [], requestCollectionsEquipped = true, debounceTime: number = 300, signal) {
    return new Promise<void>((res, rej)=>{
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(async ()=>{
        try {
          if (!dogs || dogs.length === 0) {
            return;
          }
          const nftIds:string[] = [];
          const collectionsToRequest: string[] = [];
          let propertiesToFill = ['animationFiles2D', 'animationFiles34', 'dogName', 'energy', '_id', 'number', 'items', 'level', 'paid', 'picture', 'rarities', 'xp'];
          propertiesToFill = propertiesToFill.filter((prop) => !propertiesToNotFillOut.find((notProp) => notProp === prop)); 
          dogs.forEach((minDog:Dog)=>{ 
            if (minDog && minDog.dogName) {
              return; // dog already is filled
            }
            nftIds.push(minDog.nftId); 
          });
          if (nftIds.length === 0) {
            return;
          }
          const detailedDogs:any[] = await dogApi.getDogDetails(nftIds, signal);
          dogs.forEach((minDog:Dog)=>{
            if (minDog && minDog.dogName) {
              return; // dog already is filled
            }
            const dog = detailedDogs.find((dDog:any) => dDog.nftId === minDog.nftId); 
              if (!dog) {
                return;
              }
              propertiesToFill.forEach((property) => {
                minDog[property] = dog[property];
              })
      
              if (dog.items) {
                dog.items.forEach((item:any)=>{
                  let collectionId = item.collectionId;
                  if(requestCollectionsEquipped && !collectionId) {
                    // console.log('userOverview: ', userOverview);
                    const overviewItem = userOverview.find((i:any) => i._id === item._id);
                    if (overviewItem) {
                      collectionId = overviewItem.collectionId;
                    }
                  }
                  if (requestCollectionsEquipped && collectionId && !collectionsToRequest.includes(collectionId)) {
                    collectionsToRequest.push(collectionId);
                  }
                })
              }
          });
          // console.log('collections requested from dog filler: ', collectionsToRequest)
          if (requestCollectionsEquipped) {
            await requestCollections(collectionsToRequest, false, true);
          }
        } catch(e:any) {
          console.log(e.message);
        } finally {
          res();
        }
      }, debounceTime);
     
    })
   
  } 

    /* async function refreshShop() {
        setShopAccessory([]);
    } */
    async function refreshDogs(force:boolean = true) {
        try {
                setDogsLoaded(false);
                const data = await dogApi.getDogs();
                if (mounted.current) {
                    setDogs(data);
                    setDogsLoaded(true);
                } else {
                    console.log('not mounted setting dogs');
                }
          } catch (err) {
            console.error(err);
          } 
    }
    async function refreshNotifications(force:boolean = true) {
        try {
            setNotificationsLoaded(false);
            const savedNotifications = window.localStorage.getItem('savedNotifications');
            const savedNotificationsUpdate = window.localStorage.getItem('updateNotifications');
            let data = [];
            if ((!force) && savedNotifications && parseInt(savedNotificationsUpdate) > new Date().getTime()- 30000  ) {
                data = JSON.parse(savedNotifications);
            } else  {
                data = await customerApi.getNotifications();
                try {
                    window.localStorage.setItem('savedNotifications', JSON.stringify(data));
                    window.localStorage.setItem('updateNotifications', `${new Date().getTime()}`);
                } catch(storeError) {
                  console.log('writing gift notifications to localstorage failed');
                }
            }
                if (mounted.current) {
                setNotifications(data);
                setNotificationsLoaded(true);
                }
          } catch (err) {
            console.error(err);
          } 
    }

    useEffect(()=>{
      try {
        if(auth.isAuthenticated && !initDone) {
            setInitDone(true);
            refreshDogs(false);
            loadInventory();
            refreshNotifications(false);
        }
      } catch(e:any) {
        console.log('refreshing data failed');
      }
    }, [auth, initDone])

    async function saveDataToLS(userAccessory, dogs, updateExpiry:boolean = true) {
      try {
        window.localStorage.setItem('userAccessory', JSON.stringify(userAccessory));
        window.localStorage.setItem('savedDogs', JSON.stringify(dogs));
        if(updateExpiry) {
          window.localStorage.setItem('updateUserAccessory', '' + new Date().getTime());
          window.localStorage.setItem('updateSavedDogs', `${new Date().getTime()}`);
        }
      } catch(storeError) {
        console.log(storeError.message);
        console.log('writing dogs and items to localstorage failed');
      }
    }

    const initItemsAndDogs = useCallback(async (newItems: any[], newDogs:any[]) => {
      try {
        newDogs.forEach((dog) => {
          if (!dog) {
            return;
          }
            const notes = notifications.filter((n) => n.type === 'dogGift' && n.itemId === dog._id && n.read === false);
            if(notes && notes.length > 0) {
                notes.sort((a:NotificationType, b: NotificationType) => ((new Date(b.createdAt)).getTime() || 999999999999999999999) - ((new Date(a.createdAt)).getTime() || 0)); // dates are larger if later, we want the latest dates first
                dog.gift = true;
                dog.giftFrom = notes[0].fromHandle;
                dog.notificationId = notes[0]._id;
            } else {
              dog.gift = false;
              dog.giftFrom = '';
              dog.notificationId = '';
            }
            
        });
      } catch(e:any) {
        console.log('gif notifailed', e.message);
        // gift notifcation failed
      }
      try {
        newItems.forEach((item)=> {
          if(!item) {
            return;
          }
          item.gift = false;
          item.notificationId = '';
          item.giftFrom = '';
          item.dog ='';
            const notes = notifications.filter((n)=> n.type === 'itemGift' && ((n.itemId === item._id && n.number === item.serial) || (n.nftId && n.nftId === item.nftId) ) && n.read === false);
            
            if(notes && notes.length > 0) {
                notes.sort((a:NotificationType, b: NotificationType) => ((new Date(b.createdAt)).getTime() || 999999999999999999999) - ((new Date(a.createdAt)).getTime() || 0)); // dates are larger if later, we want the latest dates first
                item.gift = true;
                item.giftFrom = notes[0].fromHandle;
                item.notificationId = notes[0]._id;
            } 

        });
        
        } catch(e:any) {
          console.log('item notifailed', e.message);
          // item notifications failed
        }
        setUserAccessoryHandler(newItems);
        setDogs(newDogs);
        setAllLoaded(true);
        saveDataToLS(newItems, newDogs);
    }, [notifications]);

    useEffect(()=>{
      try {
        // setAllLoaded(false);
        if(notificationsLoaded && dogsLoaded && itemsLoaded) {
          if (JSON.stringify(prevDogs.current) !== JSON.stringify(dogs)) {
            prevDogs.current = dogs;
            const newDogs = [...dogs];
            const newItems = [...userAccessory];
            initItemsAndDogs(newItems, newDogs);
          }
        }
      } catch (e:any) {
        console.log(e.message);
      }
    },[dogsLoaded, dogs, initItemsAndDogs, userAccessory, itemsLoaded, notificationsLoaded])

    useEffect(()=>{
        if(window.localStorage.getItem('handles')) {
          window.localStorage.removeItem('handles');
        }
    }, []);

    useEffect(() => {
      return () => {
        if (timeout) {
          clearTimeout(timeout);
        }
      };
    }, []);


  return (
    <DataContext.Provider
      value={{
        dogs, 
        userAccessory,
        userOverview,
        accessoryData,
        notifications,
        filledCollections,
        allLoaded,
        refreshOverview,
        fillDogDetails,
        requestCollections,
        refreshDogs,
        refreshNotifications,
        refreshAccessoryData,
        updateDog,
        updateItem,
        readNotification,
        readAllNotifications,
        removeItem,
        removeItems,
        removeDog,
        getDog,
        
        // refreshShop
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;